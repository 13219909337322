$_el-padding-desktop: 34px;
$_el-padding-mobile: 18px;

.tableau-panel {
  &:not(:first-child) {
    @include fluid-scale(padding-top, 70px, 38px);
  }

  &__title {
    @include fluid-scale(font-size, 30px, 24px);
    color: map-get($accent, 4);
  }

  &__intro {
    @include fluid-scale(padding-top, $_el-padding-desktop, $_el-padding-mobile);
  }

  &__body-image {
    @include fluid-scale(padding, $_el-padding-desktop, $_el-padding-mobile, $break130, $break70);
    max-width: 100%;

    &--left {
      @include fluid-scale(margin-left, -1 * $_el-padding-desktop, -1 * $_el-padding-mobile, $break130, $break70);
      float: left;
    }

    &--center {
      @include fluid-scale(margin-bottom, -1 * $_el-padding-desktop, -1 * $_el-padding-mobile, $break130, $break70);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    &--right {
      @include fluid-scale(margin-right, -1 * $_el-padding-desktop, -1 * $_el-padding-mobile, $break130, $break70);
      float: right;
    }

    @include respond($break70) {
      float: none;
      width: 100%;
      padding: $_el-padding-mobile 0 0;
    }
  }
}
