// Interior Page Hero
//
// All heroes are added through a PageHero model on the backend, then chosen for
// a particular page. Heroes need only be applied to one child page; sibling
// pages will use the same hero if they do not have their own. Currently on the
// Healthshare site, section top-level pages have a background photo; their
// child pages have a background illustration. Background colors in hero are
// inherited through the `palette-` class of the page (on `<main>`).
//
// Fields:
// * Quick Links: repeater of page or external links
// * Hero Image Type (illustration or image/photo)
// * Desktop Image
// * Mobile Image
// * Alt Text
//
// Markup: kss-markup/interior-hero.html
//
// Weight: 1
//
// Styleguide Site-Components.InteriorHero
.hero {
  color: $neutralWhite;
  background-color: var(--colorPrimary);

  @include respond($break90) {
    margin-bottom: 44px;

    &.no-margin,
    &.hero-home {
      margin-bottom: 0;
    }
  }

  .palette-3 & {
    @include respond($break90) {
      border-top: 2px solid $neutralWhite;
    }
  }

  .page {
    height: 360px;

    @include respond($break90) {
      height: auto;
    }
  }

  &:not(.hero-home) {
    .hero-content {
      max-height: 360px;

      @include respond($break90) {
        max-height: none;
      }
    }
  }

  .content-wrapper {
    position: relative;

    &.transparent {
      @include respond($break50) {
        background-image: none !important; // stylelint-disable-line
      }

      .hero-content,
      h1 {
        background-color: transparent;
        box-shadow: none;
      }

      .hero-content {
        padding-left: 0;
      }
    }

    @include respond($break90 + 1, min) {
      @include containerPrimary;
      padding-bottom: 32px;
    }

    @include respond($break90) {
      min-height: auto;

      &:not(.transparent) {
        background-image: none !important; // stylelint-disable-line
      }
    }
  }

  + .bg-gray,
  + .bg-primary {
    margin-top: 0;
  }

  .transparent {
    button.mobile {
      border-top: 2px solid white;
    }
  }

  .hero-content {
    position: relative;
    display: inline-grid;
    grid-template:
      "heading" max-content
      "nav" auto / minmax(500px, max-content);
    padding: $containerPadding75 $containerPadding40 $containerPadding40;
    background-color: var(--colorPrimary);

    @include respond($break90, min) {
      @include boxShadow;
    }

    @include respond($break90) {
      display: grid;
      grid-template:
        "heading" auto
        "image" 1fr
        "nav" auto / 100%;
      min-height: 340px;
      padding: 0;
    }

    @include respond($break60) {
      min-height: 266px;
    }
  }

  .heading-container {
    max-width: 900px;

    @include respond($break90) {
      display: none;
    }
  }

  .load-hidden {
    opacity: 0;
  }

  .heading-mobile {
    display: none;

    @include respond($break90) {
      display: block;
      @include fluidType(41px, 30px, $break50, $break30);
    }
  }

  h1 {
    position: relative;
    grid-area: heading;
    padding-bottom: $containerPadding25;
    color: $neutralWhite !important; // stylelint-disable-line

    @include respond($break90) {
      width: 100%;
      max-width: none;
      padding: 4.211vw 5.86vw 2.623vw;
    }

    @include respond($break60) {
      padding: $containerPadding40 $containerPadding15 $containerPadding15;
    }

    br {
      @include responsiveBreak;
    }

    &.post-title {
      @include respond($break90) {
        padding-bottom: 4.211vw;
      }

      @include respond($break60) {
        padding-bottom: $containerPadding40;
      }
    }
  }

  nav {
    @include fluidType(19px, 14px);
    grid-area: nav;

    @include respond($break90, min) {
      max-height: none !important; // stylelint-disable-line
    }

    @include respond($break90) {
      @include accordionMenu;
      /* autoprefixer: ignore next */
      -ms-grid-row: 3; // stylelint-disable-line

      [data-ref="fullContent"] {
        .icon {
          display: none;
        }
      }
    }

    button {
      @include respond($break90, min) {
        display: none;
      }
    }

    .heading-quaternary {
      padding: 2px 0 0 3px;
      font-weight: $bold;
      color: $neutralWhite;
      text-transform: uppercase;

      @include respond($break90) {
        display: none;
      }
    }

    ul {
      @include listUnstyled;
      max-width: 570px;
      column-count: 2;
      padding-top: 6px;
      padding-left: 4px;

      @include respond($break90) {
        columns: 1 auto;
      }
    }

    li {
      padding-top: 14px;
      padding-left: 18px;
      line-height: 1.222;
      text-indent: -34px;
      backface-visibility: hidden;

      @include respond($break90) {
        width: auto;
      }

      a {
        @include linkUnstyled;
        @include hoverFade;
      }

      .icon {
        transform: rotate(-90deg);
        backface-visibility: hidden;
      }
    }
  }

  .mobile-container {
    display: none;
    /* autoprefixer: ignore next */
    -ms-grid-row: 2; // stylelint-disable-line

    @include respond($break90) {
      display: block;
    }

    &.illustration {
      @include respond($break90) {
        display: none;
      }

      @include respond($break50) {
        display: block;
      }
    }
  }

  img,
  video {
    object-fit: cover;
    object-position: 67% top;
    width: 100%;
    height: 100%;

    &.illustration {
      object-position: right bottom;
    }

    &.desktop {
      position: absolute;
      right: 0;
      bottom: 0;

      @include respond($break90) {
        &:not(.illustration) {
          display: none;
        }
      }

      &.illustration {
        @include respond($break120) {
          width: 90%;
          height: 90%;
        }

        @include respond($break90) {
          width: 100%;
          height: 100%;
        }

        @include respond($break50) {
          display: none;
        }
      }
    }

    &.mobile {
      display: block;

      @supports (object-fit: cover) {
        max-height: 260px;
      }
    }
  }
}

// variant for blog post headers
.hero.hero-post {
  .content-wrapper {
    padding-bottom: 0;
  }

  .heading-container {
    max-width: none;
  }

  .hero-content {
    display: block;
    min-height: 290px;
    max-height: none;
    box-shadow: none;

    @include respond($break90) {
      min-height: auto;
    }
  }
}

// Homepage Hero
//
// A hero variant that contains an optional, autoplayed video background,
// heading and subheading, and link text. Modified on the backend through
// "Settings > Homepage Hero Settings".
//
// Fields:
// * Heading
// * Subheading
// * Link
// * Link Text
// * Video (mp4 and webm sources)
// * Desktop & Mobile Images (applied to the `poster` attribute of `<video>`)
//
// Markup: kss-markup/homepage-hero.html
//
// Weight: 2
//
// Styleguide Site-Components.HomepageHero
.hero.hero-home {
  overflow: hidden;
  background-color: $neutral40;

  .hero-content {
    grid-template:
      "heading" auto
      "subheading" auto / minmax(auto, 550px);
    padding: 95px 0 80px;
    background-color: transparent;
    box-shadow: none;

    @include respond($break90) {
      display: grid;
      grid-template:
        "heading" minmax(247px, 100%)
        "subheading" auto / 100%;
      min-height: 379px;
      padding: 0;
    }
  }

  video {
    height: auto;
  }

  @supports (object-fit: cover) {
    video {
      height: 100%;
    }
  }

  //targets IE Edge browser, which now supports object-fit, but not for video
  @supports (-ms-ime-align: auto) {
    video {
      height: auto;
    }
  }

  h1 {
    @include boxShadow("filter", 0.41);
    line-height: 1;

    @include respond($break90 + 1, min) {
      padding-bottom: 31px;
    }

    @include respond($break90) {
      align-self: end;
    }

    @include respond($break60) {
      padding-right: $containerPadding20;
      padding-left: $containerPadding20;
    }
  }

  .subheading {
    position: relative;
    grid-area: subheading;

    @include respond($break90 + 1, min) {
      p {
        padding: 42px 24px;
        background-color: $basePrimary;
      }

      .heading-cta {
        position: absolute;
        top: 100%;
        left: $containerPadding20;
        margin-top: 0;
        transform: translateY(-50%);
      }
    }

    @include respond($break90) {
      padding: 3.08vw 5.86vw;
      background-color: $basePrimary;

      @supports (clip-path: polygon(0 0, 100% 3.79%, 100% 100%, 0% 100%)) or
        (-webkit-clip-path: polygon(0 0, 100% 3.79%, 100% 100%, 0% 100%)) {
        padding-top: calc(3.08vw + 5px);
        clip-path: polygon(0 0, 100% 3.79%, 100% 100%, 0% 100%);
      }

      .heading-cta {
        margin-top: 17px;
      }
    }

    @include respond($break60) {
      padding: $containerPadding20;
    }

    p {
      display: inline-block;
      color: $neutralWhite;

      @include respond($break90) {
        display: block;
      }
    }

    .heading-cta {
      @include clippedBox;
      @include thinGlyphs;
      @include boxShadow;
      display: inline-block;
      font-size: 15px;
      font-weight: $black;
      text-transform: uppercase;
      background-color: $baseSecondary;

      a {
        @include hoverFade;
        color: $basePrimaryDarker;
      }
    }
  }

  .mobile-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -25;
    width: 100%;
    height: calc(100% - 108px);

    img {
      max-height: none;
    }
  }
}
