.search-results {
  h2 {
    padding-bottom: $containerPadding25;

    @include respond($break130) {
      padding-bottom: 1.953vw;
    }

    @include respond($break70) {
      padding-bottom: $containerPadding15;
    }
  }

  .results-container {
    display: flex;
    padding-top: 65px;

    @include respond($break130) {
      padding-top: 5.078vw;
    }

    @include respond($break70) {
      flex-direction: column;
      padding-top: 36px;
    }
  }

  .result, .filtered, .empty {
    padding: $containerPadding40 0 30px;
    border-top: 2px solid $neutral40;

    @include respond($break130) {
      padding: 3.125vw 0 2.344vw;
    }

    @include respond($break70) {
      padding: $containerPadding20 0 $containerPadding15;
    }
  }

  .filtered {
    min-width: 250px;
    margin-right: $containerPadding75;

    @include respond($break70) {
      min-width: none;
      padding-top: 0;
      margin-right: 0;
      border-top: none;
    }

    h4 {
      font-weight: $bold;
      text-transform: uppercase;
    }

    a {
      text-decoration: none;

      &.active {
        font-weight: $black;
        color: $basePrimary;
      }
    }

    p {
      padding-top: 11px;
      margin-top: 0;
    }
  }

  .empty {
    width: 100%;
  }

  .page-link {
    @include linkPrimary;
    @include headingQuaternary;
    @include hoverFade;
    display: inline-block;
    padding-top: $containerPadding15;
  }

  .icon {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }

  .page-select {
    padding: 23px 0 21px;
    color: var(--colorPrimary);
    text-align: center;
    border-top: 2px solid $neutral40;
    border-bottom: 2px solid $neutral40;

    a {
      @include linkUnstyled;
      @include hoverFade;

      &:not(:last-child) {
        padding-right: 32px;

        @include respond($break70) {
          padding-right: 27px;
        }
      }
    }
  }

  .page-number {
    display: inline-block;
    font-size: 12px;

    &.active {
      font-weight: $bold;
      text-decoration: underline;
    }
  }

  .caret-back {
    .icon {
      transform: rotate(90deg);
    }
  }
}
