.immunization-datepicker {
  .content-rte {
    .heading, .subheading {
      color: $neutralWhite;
    }
  }

  .heading {
    padding-bottom: 23px;
  }

  .button-primary {
    @include hoverFade;

    &::before {
      background: map-get($accent, 1);
    }
  }

  .subheading {
    padding-bottom: $containerPadding25;

    @include respond($break70) {
      padding-bottom: $containerPadding20;
    }
  }

  .form {
    display: flex;
    align-items: baseline;

    @include respond($break70) {
      flex-direction: column;
    }

    .form-section {
      margin-top: auto;

      &:not(:last-child) {
        padding-right: $containerPadding40;
      }

      @include respond($break70) {
        width: 100%;

        &:not(:last-child) {
          padding-right: 0;
        }

        &:not(:first-child) {
          padding-top: $containerPadding25;
        }
      }
    }
  }

  .dropdown-container {
    position: relative;
    display: inline-block;
    background-color: $neutralWhite;

    &.month {
      width: 130px;

      @include respond($break80) {
        width: 119px;
      }

      @include respond($break70) {
        width: calc(60% - 10px);
      }
    }

    &.year {
      width: 80px;
      margin-left: 15px;

      @include respond($break80) {
        width: 65px;
        margin-left: 1px;
      }

      @include respond($break70) {
        width: calc(40% - 10px);
        margin-left: 15px;
      }
    }
  }

  .dropdown {
    display: inline-block;
    max-width: 100%;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0;
    appearance: none;

    @include respond($break80) {
      font-size: 15px;
    }

    &::-ms-expand {
      display: none;
    }
  }

  .icon-container {
    position: absolute;
    top: 6px;
    right: 5px;
    display: inline-block;
    color: map-get($accent, 1);
  }
}
