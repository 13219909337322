// Component Typography and Prototypes
// -----------------------------------

// Template typography mixins/prototypes and utility classes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

//Utilities
// --------------------------------------------------------
@use "sass:math";

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

// Set min and max font-sizes and breakpoints and let SCSS scale fluidly in-between
// Adapted from https://css-tricks.com/snippets/css/fluid-typography/
// Usage: @include fluid-type(22px, 16px, $break130, $break50);
@mixin fluidType(
  $max-font-size,
  $min-font-size,
  $max-vw: $break130,
  $min-vw: $break50
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    font-size: $max-font-size;

    @include respond($max-vw) {
      font-size: calc(
        #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
          ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
      );
    }

    @include respond($min-vw) {
      font-size: $min-font-size;
    }
  }
}

.align-center {
  text-align: center;
}

@mixin responsiveBreak {
  @include respond($break90) {
    display: none;
  }
}

.responsive-break {
  @include responsiveBreak;
}

// Headings
// --------------------------------------------------------

// Primary Heading
//
// Weight: 1
//
// Large heading for heroes
//
// Markup:
// <h1 class="heading-primary">A Primary Heading</h1>
//
// Styleguide STACSS.Typography.headingPrimary

@mixin headingPrimary {
  @include thinGlyphs;
  @include fluidType(79px, 41px);
  font-weight: $black;
  line-height: 0.95;
  color: var(--colorSecondary);
}

.heading-primary {
  @include headingPrimary;
}

// Secondary Heading
//
// Weight: 2
//
// Heading for sections and fast facts
//
// Markup:
// <h2 class="heading-secondary">A Secondary Heading</h2>
//
// Styleguide STACSS.Typography.headingSecondary

@mixin headingSecondary {
  @include thinGlyphs;
  @include fluidType(40px, 22px);
  font-weight: $black;
  line-height: 1.125;
  color: var(--colorSecondary);

  @include respond($break50) {
    line-height: 1.182;
  }
}

.heading-secondary {
  @include headingSecondary;
}

// Tertiary Heading
//
// Weight: 3
//
// Heading for repeating callouts
//
// Markup:
// <h3 class="heading-tertiary">A Tertiary Heading</h3>
//
// Styleguide STACSS.Typography.headingTertiary

@mixin headingTertiary {
  @include thinGlyphs;
  @include fluidType(24px, 18px);
  font-weight: $bold;
  color: var(--colorSecondary);

  @include respond($break50) {
    font-size: 24px;
    line-height: 1;
  }
}

.heading-tertiary {
  @include headingTertiary;
}

// Quaternary Heading
//
// Weight: 4
//
// Usually used as a subheading
//
// Markup:
// <h4 class="heading-quaternary">A Quaternary Heading</h4>
//
// Styleguide STACSS.Typography.headingQuaternary

@mixin headingQuaternary {
  @include thinGlyphs;
  @include fluidType(20px, 18px, $break70);
  font-weight: $black;
  color: var(--colorSecondaryDark);
}

.heading-quaternary {
  @include headingQuaternary;
}

// Quinary Heading
//
// Weight: 5
//
// Header for Call to Acion callouts
//
// Markup:
// <h5 class="heading-quinary">A Quinary Heading</h5>
//
// Styleguide STACSS.Typography.headingQuinary

@mixin headingQuinary {
  @include thinGlyphs;
  @include fluidType(24px, 16px);
  font-weight: $bold;
  line-height: 1.25;
  color: var(--colorSecondaryDark);
  text-transform: uppercase;
}

.heading-quinary {
  @include headingQuinary;
}

// Copy
// --------------------------------------------------------

// Default Copy with Link
//
// Weight: 6
//
// Standard copy with inline link
//
// Markup:
// <p class="copy-primary">Here is some standard copy <a class="link-primary" href="#">and here is a link</a>. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//
// Styleguide STACSS.Typography.copyPrimary

@mixin copyPrimary {
  @include fluidType(18px, 16px, $break70);
  font-weight: $book;
  line-height: 1.667;

  @include respond($break50) {
    font-weight: $light;
    line-height: 1.5;
  }

  @include respond($break50, min) {
    @include thinGlyphs;
  }
}

.copy-primary {
  @include copyPrimary;
}

@mixin lineHeightSmall {
  line-height: 1.567;

  @include respond($break50) {
    line-height: 1.4;
  }
}

// Links
// --------------------------------------------------------
@mixin linkPrimary {
  @include thinGlyphs;
  font-weight: $bold;
  color: inherit;
  text-decoration: underline;
}

.link-primary {
  @include linkPrimary;
}

@mixin quickLinkPrimary {
  @include fluidType(19px, 16px);
  font-weight: $bold;
  color: inherit;
  text-decoration: none;

  @include respond($break50) {
    font-weight: $black;
  }
}

// Lists
// --------------------------------------------------------

// Unordered Bulleted List
//
// Weight: 7
//
// Default bulleted list
//
// Markup:
// <ul class="list-primary">
//   <li>The first thing</li>
//   <li>And the second</li>
//   <li>And the third</li>
// </ul>
//
// Styleguide STACSS.Typography.listPrimary

@mixin listPrimary {
  @include listUnstyled;
  @include fluidType(18px, 16px, $break70);
  font-weight: $book;
  line-height: 1.667;
  list-style: inside;

  [dir="rtl"] & {
    padding-right: 0;
  }

  li {
    padding-left: 30px;
    text-indent: -30px;

    [dir="rtl"] & {
      padding-right: 30px;
      padding-left: 0;
    }
  }

  li::before {
    display: inline-block;
    width: 8px;
    content: "";
  }

  @include respond($break50) {
    font-weight: $light;
    line-height: 1.5;
  }
}

.list-primary {
  @include listPrimary;
}

// Ordered List
//
// Weight: 8
//
// Default numbered list
//
// Markup:
// <ol class="ordered-list-primary">
//   <li>The first thing</li>
//   <li>And the second</li>
//   <li>And the third</li>
// </ol>
//
// Styleguide STACSS.Typography.orderedListPrimary

@mixin orderedListPrimary {
  @include listPrimary;
  list-style: decimal inside;

  li {
    padding-left: 40px;
    text-indent: -40px;
  }

  li::before {
    display: inline-block;
    width: 20px;
    content: "";
  }
}

.ordered-list-primary {
  @include orderedListPrimary;
}

// Unordered Un-Bulleted List
//
// Weight: 9
//
// Un-bulleted list
//
// Markup:
// <ul class="list-secondary">
//   <li>So many things to list!</li>
//   <li>Here is another</li>
//   <li>And yet another</li>
// </ul>
//
// Styleguide STACSS.Typography.listSecondary

@mixin listSecondary {
  @include listPrimary;
  list-style: none;

  li::before {
    display: none;
  }
}

.list-secondary {
  @include listSecondary;
}

.font-noto-sans {
  font-family: $font-stack-noto-sans;
}
