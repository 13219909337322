// Variables
//----------

// Colors
// --------------------------------------------------------

// Neutral Colors
//
// Weight: 1
//
// Copy text on a $neutralWhite backgound should be $neutral90. $neutral20 is used for the gray callout boxes. $neutral30 and $neutral60 are used in the language dropdown. $neutral40 is used for divider lines.
//
// $neutralWhite - #ffffff;
// $neutralBlack - #000000;
// $neutral20 - #F7F7F7;
// $neutral30 - #F0F0F0;
// $neutral40 - #ECECEC;
// $neutral60 - #707070;
// $neutral90 - #646569;
//
// Styleguide Variables.neutralColors

$neutralWhite: #ffffff;
$neutralBlack: #000000;

$neutral20: #f7f7f7;
$neutral30: #f0f0f0;
$neutral40: #ececec;
$neutral60: #707070;
$neutral90: #646569;

// Site-Wide Base Colors
//
// Weight: 2
//
// For stats, fast facts, quotes, and video buttons throughout. $basePrimary is used for backgrounds and $basePrimaryDark is for text and buttons. $baseSecondary is used for the arrow icons on the card callout mobile slider. When styling headings and theme elements on `:root` pages, using `var(--colorPrimary)` and `var(--colorSecondary)` both result in `$basePrimary`.
//
// $basePrimary - #3198B6;
// $basePrimaryDark - #3998B5;
// $baseSecondary - #FFCE2D;
//
// Styleguide Variables.baseColors

$basePrimary: #3198b6;
$basePrimaryDark: #1c80a1;
$basePrimaryDarker: #066089;
$baseSecondary: #ffce2d;

// Member Colors
//
// Weight: 3
//
// `.palette-1`, where $accent 1 is for hero backgrounds and buttons and $accent 3 is for headers and subheaders.
//
// var(--colorPrimary) - #AF66B3; map-get($accent, 1)
// var(--colorSecondary) - #90AE4F; map-get($accent, 3)
//
// Styleguide Variables.memberColors

// Provider Colors
//
// Weight: 4
//
// `.palette-2`, where $accent 2 is for hero backgrounds and buttons and $accent 1 is for headers and subheaders
//
// var(--colorPrimary) - #95C0CE; map-get($accent, 2)
// var(--colorSecondary) - #AF66B3; map-get($accent, 1)
//
// Styleguide Variables.providerColors

// Commitment to Health Colors
//
// Weight: 5
//
// `.palette-3`, where $accent 3 is for hero backgrounds and buttons and $accent 4 is for headers and subheaders
//
// var(--colorPrimary) - #90AE4F; map-get($accent, 3)
// var(--colorSecondary) - #405866; map-get($accent, 4)
//
// Styleguide Variables.commitmentColors

// About Us Colors
//
// Weight: 6
//
// `.palette-4`, where $accent 4 is for hero backgrounds and buttons and $accent 3 is for headers and subheaders
//
// var(--colorPrimary) - #405866; map-get($accent, 4)
// var (--colorSecondary) - #90AE4F; map-get($accent, 3)
//
// Styleguide Variables.aboutColors
$accent: (
  1: #af66b3,
  2: #95c0ce,
  3: #90ae4f,
  4: #405866,
);

$accentDark: (
  1: #a35ca7,
  2: $basePrimaryDark,
  3: #7a9e3c,
  4: #405866,
);

//Set base color palette and theme classes

:root {
  --colorPrimary: #{$basePrimary};
  --colorPrimaryDark: #{$basePrimaryDark};
  --colorSecondary: #{$basePrimaryDark};
}

.palette-1 {
  --colorPrimary: #{map-get($accent, 1)};
  --colorPrimaryDark: #{map-get($accentDark, 1)};
  --colorSecondary: #{map-get($accent, 3)};
  --colorSecondaryDark: #{map-get($accentDark, 3)};
}

.palette-2 {
  --colorPrimary: #{map-get($accentDark, 2)};
  --colorPrimaryDark: #{map-get($accentDark, 2)};
  --colorSecondary: #{map-get($accent, 1)};
  --colorSecondaryDark: #{map-get($accentDark, 1)};
}

.palette-3 {
  --colorPrimary: #{map-get($accent, 3)};
  --colorPrimaryDark: #{map-get($accentDark, 3)};
  --colorSecondary: #{map-get($accent, 4)};
  --colorSecondaryDark: #{map-get($accentDark, 4)};
}

.palette-4 {
  --colorPrimary: #{map-get($accent, 4)};
  --colorPrimaryDark: #{map-get($accentDark, 4)};
  --colorSecondary: #{map-get($accent, 3)};
  --colorSecondaryDark: #{map-get($accentDark, 3)};
}

$formBgColor: rgba($neutral30, 0.61);

// Responsive breakpoints
// --------------------------------------------------------
$break150: 1550px;
$break130: 1280px;
$break120: 1230px;
$break115: 1170px;
$break110: 1100px;
$break100: 1060px;
$break90: 950px;
$break80: 840px;
$break70: 760px;
$break60: 660px;
$break50: 470px;
$break40: 400px;
$break30: 320px;
// $break20: ;
// $break10: ;

// Measurements
// --------------------------------------------------------
$baseLineHeight: 1.4;
$verticalSpace: $baseLineHeight * 1em;

$containerMaxWidth: $break130;
$containerBaseWidth: 1130px;

$containerPadding75: 75px;
$containerPadding50: 50px;
$containerPadding40: 40px;
$containerPadding25: 25px;
$containerPadding20: 20px;
$containerPadding15: 15px;

// Font Weights
// --------------------------------------------------------
$light: 300;
$book: 400;
$bold: 700;
$black: 900;

// Animations
// --------------------------------------------------------
// $duration: 0.2s;
$timing: ease-in-out;
$colorTransition: color 0.2s $timing;
