body {
  &.no-scroll {
    position: fixed;
    overflow: hidden;

    > .scroll-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-color: transparent;
      transition: background-color 0.2s $timing;

      &.active {
        pointer-events: auto;
        background-color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}
