@use "sass:math";

.post-list {
  $_gutter-desktop: 54px;
  $_gutter-mobile: 20px;
  $_min-width: 250px;

  display: flex;
  flex-flow: row wrap;
  margin-top: -1 * $_gutter-desktop;
  margin-left: -1 * $_gutter-desktop;

  &__item {
    flex-basis: calc(#{percentage(math.div(1, 3))} - #{$_gutter-desktop});
    flex-grow: 1;
    min-width: $_min-width;
    margin-top: $_gutter-desktop;
    margin-left: $_gutter-desktop;

    @include respond(1000px, min) {
      max-width: calc(#{percentage(math.div(1, 3))} - #{$_gutter-desktop});
    }
  }

  @supports (grid-auto-columns: min-content) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($_min-width, 1fr));
    grid-gap: $_gutter-desktop;
    margin-top: 0;
    margin-left: 0;

    @include respond($break130) {
      grid-gap: strip-unit(math.div($_gutter-desktop, $break130)) * 100vw;
    }

    @include respond($break50) {
      grid-gap: $_gutter-mobile;
    }

    &__item {
      min-width: auto;
      max-width: none;
      margin-top: 0;
      margin-left: 0;
    }
  }
}
