.partner-logos {
  &.content-section:last-child {
    margin-bottom: 29px;

    @include respond($break70) {
      margin-bottom: 15px;
    }
  }

  ul {
    @include listUnstyled;

    @supports (grid-auto-columns: min-content) {
      display: grid;
      /* autoprefixer: ignore next */
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }

  li {
    display: grid;
  }

  img {
    max-width: 154px;
  }
}
