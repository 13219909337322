// Block Callout
//
// A full-width callout with text and image for fast facts. Uses shared `callout-image` styles, which can be found in `themes/healthshare/assets/style/stylesheets/components/bucket`
//
// Fields:
// * `image`
// * `alt`
// * `image_focal_point_x`
// * `image_focal_point_y`
// * `heading`
// * `body`
//
// Markup: kss-markup/block-callout.html
//
// Weight: 7
//
// Styleguide Content-Elements.BlockCallout
.block-callout {
  .image-container {
    margin-left: -100px;

    [dir='rtl'] & {
      margin-right: -100px;
      margin-left: 0;
    }

    @include respond($break70) {
      margin-bottom: -11.5vw;
      margin-left: 0;

      [dir='rtl'] & {
        margin-right: 0;
      }
    }
  }

  .text-container {
    max-width: calc(50% + 100px);

    @include respond($break70) {
      max-width: 100%;
    }
  }

  figcaption {
    padding: $containerPadding75;

    @include respond($break130) {
      padding: 5.86vw $containerPadding75 5.86vw 5.86vw;

      [dir='rtl'] & {
        padding-right: 5.86vw;
        padding-left: $containerPadding75;
      }
    }

    @include respond($break70) {
      padding-right: 5.86vw;
      text-align: center;
    }

    @include respond($break50) {
      padding: 5.86vw $containerPadding20 $containerPadding40;
    }
  }

  header {
    position: relative;
  }

  h2 {
    @include clippedBox($containerPadding25, 13px);
    color: $basePrimary;
    background: $neutralWhite;

    @include respond($break70) {
      @include clippedBox($containerPadding20, 10px);
    }
  }

  .body-text {
    @include lineHeightSmall;
    padding: $containerPadding40 100px 0 0;
    color: $neutralWhite;

    [dir='rtl'] & {
      padding-right: 0;
      padding-left: $containerPadding40;
    }

    @include respond($break130) {
      padding-right: 7.8vw;
    }

    @include respond($break70) {
      padding: $containerPadding40 0 0;
    }
  }
}
