.site-header {
  @include boxShadow;
  width: 100vw;
  max-width: 100%;

  .container-primary {
    display: grid;
    grid-template:
      'logo . secondary-nav' max-content
      'logo . site-nav' auto
      '. . site-nav' 25px / 145px minmax($containerPadding40, 1fr) minmax(max-content, 820px);
    padding-top: $containerPadding40;

    @include respond($break90) {
      grid-template:
        'logo hamburger' auto
        'secondary-nav secondary-nav' 44px / 1fr 1fr;
      grid-gap: $containerPadding20 - 5px 2px;
      padding-top: $containerPadding20;
    }
  }

  .logo {
    @include linkUnstyled;
    @include hoverFade;
    position: relative;
    grid-area: logo;

    &.nav-active {
      img {
        &.logo-color {
          opacity: 0;
        }

        &.logo-white {
          opacity: 1;
        }
      }
    }

    &.nav-closed {
      img {
        transition-delay: 0s;
      }
    }

    img {
      width: 145px;
      height: 117px;
      transition: opacity 0.3s 0.3s $timing;

      @include respond($break90) {
        width: 60px;
        height: 48px;
      }

      &.logo-white {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  .desktop-site-navigation {
    grid-area: site-nav;
    align-self: end;
    justify-self: stretch;

    @include respond($break90) {
      display: none;
    }
  }

  .hamburger {
    @include buttonUnstyled;
    @include hoverFade;
    position: relative;
    display: none;
    grid-area: hamburger;
    justify-self: end;
    color: $basePrimary;

    .icon {
      width: 48px;
      height: 48px;

      &.compatibility {
        display: none;
      }

      rect {
        opacity: 1;
        transition: opacity 0.4s 0.1s $timing,
          transform 0.4s 0.1s $timing;
        transform: rotate(0deg);

        &:nth-of-type(1) {
          transform-origin: 16px 26px;
        }

        &:nth-of-type(3) {
          transform-origin: 16px 36px;
        }
      }
    }

    &.nav-active {
      color: $neutralWhite;

      &:hover {
        color: rgba($neutralWhite, 0.7);
      }

      .compatibility {
        display: inline-block;
      }

      .default {
        display: none;
      }

      //work-around to account for IE's incorrect interpretation of rotate()
      @supports (object-fit: cover) {
        .compatibility {
          display: none;
        }

        .default {
          display: inline-block;
        }
      }

      rect {
        &:nth-of-type(1) {
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }
      }
    }

    &.nav-closed {
      transition-delay: 0.3s;

      .icon {
        rect {
          transition-delay: 0s;
        }
      }
    }

    @include respond($break90) {
      display: block;
      transition:
        opacity 0.5s $timing,
        color 0.1s $timing;
    }
  }

  .secondary-navigation {
    display: flex;
    grid-area: secondary-nav;
    align-items: center;
    /* autoprefixer: ignore next */
    -ms-grid-column-align: end;
    justify-self: end;
    margin-left: 370px;
    font-size: 15px;
    font-weight: $light;
    text-transform: uppercase;

    @include respond($break130) {
      margin-left: calc(48vw - 240px);
    }

    @supports (grid-auto-columns: min-content) {
      margin-left: unset;
    }

    @include respond($break90) {
      justify-self: auto;
      margin-right: -5.86vw;
      margin-left: -5.86vw;
      overflow: hidden;

      > * {
        @include thinGlyphs;
        @include hoverFade;
        flex: 1;
        min-width: 50vw;
        max-width: 50vw;
        height: 100%;
        font-weight: $black;
        color: $neutralWhite;
        text-align: center;
        background-color: map-get($accent, 3);
      }
    }

    @include respond($break50) {
      margin-right: -$containerPadding20;
      margin-left: -$containerPadding20;
    }
  }

  .dropdown {
    margin-left: 23px;

    .mobile-icon {
      display: none;
    }

    select {
      @include respond($break90 + 1, min) {
        max-width: 175px;
      }
    }

    @include respond($break90) {
      margin-left: 0;
      border-left: 2px solid;
      transition:
        transform 0.5s $timing,
        background-color 0.2s $timing;

      select {
        height: 100%;
        padding: 0 calc(25vw - 70px);
      }

      .icon {
        display: none;
      }

      .mobile-icon {
        top: 9px;
        right: calc(50% - 70px);
        display: block;
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .link {
    @include linkUnstyled;
    height: 28px;
    padding-top: 6px;
    margin-left: 23px;

    @include respond($break90) {
      display: none;
    }
  }

  &__button {
    @include buttonSecondary;
    @include hoverFade;

    &--desktop {
      margin-left: 23px;

      @include respond($break90) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include respond($break90) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
