@use "sass:math";

.single-post {
  &__figure {
    background-color: var(--colorPrimary);
  }

  &__image {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 3.556em;
  }

  &__content-block {
    padding-right: $containerPadding50;
    padding-left: $containerPadding50;

    @include respond($break130) {
      padding-right: strip-unit(math.div($containerPadding50, $break130)) * 100vw;
      padding-left: strip-unit(math.div($containerPadding50, $break130)) * 100vw;
    }

    @include respond($break50) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__teaser {
    @include headingSecondary;
  }

  &__byline-date-block {
    display: flex;
    margin-top: 2.5em;
  }

  &__author-photo {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__byline {
    @include copyPrimary;

    > * {
      display: block;
    }
  }

  &__author-name {
    @include headingTertiary;
    line-height: 1.35;

    @include respond($break50) {
      font-size: 18px;
    }
  }

  &__body {
    margin-top: 2.222em;
  }
}
