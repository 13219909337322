.card-wrapper {
  @include listUnstyled;
  @include containerPrimary;
  display: flex;
  flex-wrap: wrap;
  transition: transform 0.3s ease-out;
  transform: translateX(0%);

  .card {
    flex: 1;
    min-width: 205px;
    margin: 5px;
  }

  // Use grid if supported
  // Note: IE11 supports an older spec for `display: grid`,
  // so test for a newer grid spec property to scope implementation properly

  @supports (grid-auto-columns: min-content) {
    /* autoprefixer: off */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    grid-gap: 10px;

    &.four-grid {
      grid-template-columns: repeat(4, minmax(205px, 1fr));

      @include respond($break90) {
        /* autoprefixer: off */
        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
      }
    }

    &.five-grid {
      /* autoprefixer: off */
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .card {
      width: unset;
      min-width: unset;
      margin: unset;
    }
  }
}
