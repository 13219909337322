.content-404 {
  padding-top: 30px;

  @include respond($break130) {
    padding-top: 2.344vw;
  }

  @include respond($break90) {
    padding-top: 0;
  }

  h1 {
    padding-bottom: $containerPadding20;

    @include respond($break130) {
      padding-bottom: 1.563vw;
    }

    @include respond($break70) {
      padding-bottom: 10px;
    }
  }

  .illustration-container {
    position: relative;
    height: 304px;

    @include respond($break130) {
      height: 23.75vw;
    }

    @include respond($break50) {
      height: 170px;
      padding-top: $containerPadding40;
    }
  }

  img {
    position: absolute;
  }

  .bandaids {
    top: -53px;
    right: -134px;
    width: 271px;

    @include respond($break130) {
      top: -4.141vw;
      right: -10.469vw;
      width: 21.172vw;
    }

    @include respond($break50) {
      top: -29px;
      right: -50px;
      width: 152px;
    }
  }

  .stethoscope {
    right: 10px;
    bottom: -135px;
    width: 326px;

    @include respond($break130) {
      right: 0.781vw;
      bottom: -10.547vw;
      width: 25.469vw;
    }

    @include respond($break50) {
      right: 36px;
      bottom: -54px;
      width: 160px;
    }
  }

  .notebook {
    right: 345px;
    bottom: -25px;
    width: 219px;

    @include respond($break130) {
      right: 26.953vw;
      bottom: -1.953vw;
      width: 17.109vw;
    }

    @include respond($break50) {
      right: 191px;
      bottom: 38px;
      width: 105px;
    }
  }
}
