// Block Call to Action
//
// Block with image, link repeater, and download repeater. The dowload repeater has a nested languages repeater, where the backend user can supply links to different language versions. When the frontend user interacts with the dropdown, the link associated with the corresponding button is updated via the `SelectLanguage` JavaScript behavior. Like the block callout content element, this element features an `object-fit` image that occupies the right column of the container on desktop (and the top block on mobile), so both use shared `callout-image` styles that can be found in  `themes/healthshare/assets/style/stylesheets/components/bucket`
//
// Fields:
// * `image`
// * `alt`
// * `image_focal_point_x`
// * `image_focal_point_y`
// * `heading`
// * `body`
// * `downloads` repeater with `link_text` field and `languages` repeater with `url` and `language_name` fields
// * `links` repeater with `url` and `link_text` fields
//
// Markup: kss-markup/block-cta.html
//
// Weight: 8
//
// Styleguide Content-Elements.BlockCTA
.block-cta {
  .text-container {
    max-width: 50%;
    background: $neutralWhite;

    @include respond($break130) {
      padding: 3.1vw;
    }

    @include respond($break70) {
      max-width: 100%;
    }

    @include respond($break50) {
      padding: $containerPadding20;
    }
  }

  header {
    + .body {
      padding-top: $containerPadding25;

      @include respond($break70) {
        padding-top: 2px;
      }
    }
  }

  .link-container {
    padding-top: 10px;

    @include respond($break70) {
      padding-top: 0;
    }
  }

  .button-wrapper {
    display: inline-block;
    padding-top: $containerPadding20;
    padding-right: $containerPadding40;

    [dir='rtl'] & {
      padding-right: 0;
      padding-left: $containerPadding40;
    }
  }

  .button-primary {
    margin-right: 0;
  }

  .dropdown-container {
    padding-top: $containerPadding20;
  }

  .dropdown {
    font-size: 15px;

    select {
      padding-right: 72px;
      padding-bottom: 4px;
    }
  }
}
