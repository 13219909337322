.desktop-site-navigation {
  $lateralLinkPadding: 10px;

  a {
    @include linkUnstyled;
  }

  ul {
    @include listUnstyled;
  }

  .level-1 {
    @include thinGlyphs;
    @include fluidType(19px, 13px, $break150, $break90);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-weight: $bold;
    line-height: normal;
    color: $basePrimaryDark;
    text-transform: uppercase;
    transition: font-size 0.2s $timing;

    > li {
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1;

      @include respond($break115) {
        max-width: 180px;
      }

      > a {
        display: inline-block;
        padding: $containerPadding25 $lateralLinkPadding;
        transition: color 0.2s $timing;

        &.active {
          color: var(--colorPrimary);
        }

        &.inactive {
          transition-delay: 0.1s;
        }

        .icon {
          vertical-align: baseline;
        }
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
  }

  .level-2 {
    @include boxShadow;
    @include transitionFade;
    position: absolute;
    top: 100%;
    left: $lateralLinkPadding - $containerPadding50;
    display: flex;
    padding: $containerPadding40 $containerPadding50;
    font-size: 18px;
    font-weight: $bold;
    color: $neutralWhite;
    text-transform: none;
    background-color: var(--colorPrimary);

    &.inactive {
      transition-delay: 0.1s;
    }

    &.animating {
      transition: opacity 0.2s $timing;
    }

    a {
      @include linkUnstyled;
      @include hoverFade;
      padding-top: 3px;
    }

    .column {
      flex: auto;
      min-width: 165px;

      + .column {
        margin-left: $containerPadding20;
      }

      > .level-3 {
        margin-top: 20px;
      }

      // affects only a column of childless links
      &:not(.level-3) > li,
      &:not(.level-3) > a {
        line-height: 1.222;

        + li {
          margin-top: $containerPadding50;
        }
      }
    }
  }

  .level-3:not(.column) {
    font-size: 16px;
    font-weight: $book;

    li + li {
      margin-top: 20px;
    }
  }

  .level-3 {
    &.column {
      line-height: 1.222;
    }

    li + li {
      margin-top: 34px;
    }
  }
}
