// Mixins
// -----

@mixin embed-font($font-name, $font-filepath-and-name, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src: url('../fonts/#{$font-filepath-and-name}.woff2') format('woff2'),
      url('../fonts/#{$font-filepath-and-name}.woff') format('woff');
  }
}

// Fonts
// --------------------------

@import url('//hello.myfonts.net/count/379af2');

@include embed-font('Sailec', 'Sailec-Regular', $book, normal);
@include embed-font('Sailec', 'Sailec-RegularItalic', $book, italic);
@include embed-font('Sailec', 'Sailec-Bold', $bold, normal);
@include embed-font('Sailec', 'Sailec-BoldItalic', $bold, italic);
@include embed-font('Sailec', 'Sailec-Light', $light, normal);
@include embed-font('Sailec', 'Sailec-Black', $black, normal);

$font-stack-default: 'Sailec', helvetica, arial, sans-serif;
$font-stack-noto-sans: 'Noto Sans', $font-stack-default;
