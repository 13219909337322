// FAQ Accordion
//
// A list of questions that can be expanded to reveal the answer via the `ExpandAccordion` JavaScript behavior. FAQs are entered via record. When adding a FAQ content element to the page, the backend user selects a category-Member or Provider. All FAQs in that category will be displayed, along with an optional `heading` for the content element. A styled RTE table can be included within a FAQ answer. To apply heading styles to the table, the user can select the `table-heading` markup class.
//
// Fields:
// * `type` (member or provider)
// * `question`
// * `answer`
//
// Markup: kss-markup/faq-accordion.html
//
// Weight: 12
//
// Styleguide Content-Elements.FAQAccordion

// Accordion
//
// A list of questions/headings that can be expanded to reveal more content via the `ExpandAccordion` JavaScript behavior. This has identical styling to the FAQ accordion CE. The only difference is that content in this element is not entered by record, but by a simple backend repeater.
//
// Fields:
// * `background_color`
// * `heading`
// * repeater with `title` and `content` fields
//
// Markup: kss-markup/accordion.html
//
// Weight: 13
//
// Styleguide Content-Elements.Accordion
.faq-accordion {
  h2 {
    padding-bottom: 38px;

    @include respond($break130) {
      padding-bottom: 2.969vw;
    }

    @include respond($break70) {
      padding-bottom: $containerPadding20;
    }
  }

  .accordion {
    border-top: 2px solid $neutral40;

    &:last-child {
      border-bottom: 2px solid $neutral40;
    }
  }

  button {
    display: flex;

    @include respond($break70) {
      justify-content: space-between;
      width: 100%;
    }
  }

  .icon-container {
    align-self: flex-start;
    padding-top: 5px;

    @include respond($break70) {
      order: 2;
      padding-top: 8px;
      padding-left: $containerPadding25;
    }
  }

  .question {
    display: inline-block;
    padding-bottom: 6px;
    padding-left: 13px;
    font-weight: $black;
    text-align: left;

    @include respond($break70) {
      order: 1;
      padding-left: 0;
    }
  }

  .answer {
    padding-bottom: 14px;
    padding-left: 38px;
    margin-top: -13px;

    @include respond($break70) {
      padding-left: 0;
      margin-top: -11px;
    }
  }

  table {
    margin-top: 10px;
    margin-bottom: $containerPadding20;
  }

  td {
    padding-top: $containerPadding20;
    padding-bottom: $containerPadding15;
    border-bottom: 2px solid $neutral40;

    @include respond($break70) {
      border-bottom: none;
    }
  }
}
