.callout-image {
  display: flex;

  @include respond($break70) {
    flex-direction: column;
  }

  .image-container {
    position: relative;
    flex: 1;
    order: 2;
    max-width: 50%;

    @include respond($break70) {
      flex: 0 auto;
      order: 1;
      max-width: 100%;
      height: 232px;
    }
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;

    [dir='rtl'] & {
      right: auto;
      left: 0;
    }

    @include respond($break70) {
      position: static;
    }
  }

  .text-container {
    flex: 1;
    order: 1;

    @include respond($break70) {
      flex: 0 auto;
      order: 2;
    }
  }
}
