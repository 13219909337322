// RAE Grid
//
// A grid of links to Risk Accepting Entities (RAE) styled primary with shared `card` and `card-wrapper`. Unlike link cards and callout cards, the RAE cards stack on mobile rather than using the `slider-wrapper`. RAEs are entered via record. When adding a RAE grid to a page, the backend user selects a category of RAE—medical, dental, or behavioral. All records in that category will be displayed, along with an optional `heading` and `subheading`
//
// Fields:
// * `name`
// * `type` (medical, dental, or behavioral)
// * `providers_url`
// * `prescriptions_url`
// * `logo`
//
// Markup: kss-markup/rae-grid.html
//
// Weight: 11
//
// Styleguide Content-Elements.RAEGrid
.rae-grid {
  header {
    padding-bottom: 34px;
  }

  h2 {
    padding-bottom: 30px;
  }

  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 172px;
    background-color: var(--colorPrimary);
  }

  img {
    width: auto;
    height: auto;

    @supports (object-fit: scale-down) {
      object-fit: scale-down;
      max-width: 190px;
      height: 130px;
    }
  }

  .body {
    p {
      a {
        font-weight: $black;
      }

      + p {
        margin-top: 13px;
      }
    }
  }
}
