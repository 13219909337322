.fr-dii {
  display: inline;
  margin: 10px 15px 10px 15px;
}

.fr-dib {
  display: block;
  margin: 10px auto;
}

.fr-fir {
  float: right;
  &.fr-dib {
    float: none;
    margin-right: 0;
  }
}

.fr-fil {
  float: left;
  &.fr-dib {
    float: none;
    margin-left: 0;
  }
}
