// Global z-index
.scroll-overlay.active {
  z-index: 1000;

  ~ .site-header {
    .logo.nav-active, .hamburger.nav-active {
      z-index: 1050;
    }
  }
}

.scroll-is-active {
  z-index: 1025;
}

.desktop-site-navigation .level-2 {
  z-index: 100;
}

.mobile-site-navigation:not(.scroll-is-active) {
  z-index: 200;
}

.card {
  article {
    z-index: 25;

    &::before {
      z-index: -25;
    }
  }
}

.block-callout {
  header {
    z-index: 25;
  }
}

.hero {
  .hero-content {
    z-index: 75;
  }

  video {
    z-index: 50;
  }

  .compatibility {
    z-index: 25;
  }
}

.immunization-datepicker {
  .dropdown-container {
    z-index: 15;
  }

  .dropdown {
    z-index: 25;
  }

  .icon-container {
    z-index: -1;
  }
}
