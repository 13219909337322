@media print {
  .site-header, footer {
    display: none !important; //stylelint-disable-line
  }
}

@page {
  size: auto;
  margin: 0;
}
