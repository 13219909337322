.tableau-select {
  $_icon-size: 1.2em;

  @include thinGlyphs;
  position: relative;
  font-size: 15px;
  font-weight: $bold;
  color: $neutral90;
  border-top: 1px solid $neutral60;
  border-bottom: 1px solid $neutral60;

  @include respond($break60 + 1, min) {
    display: none;
  }

  &__select {
    @include inputUnstyled;
    width: 100%;
    height: 40px;
    padding-right: calc(#{$_icon-size} + 5px);
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: $_icon-size;
    height: $_icon-size;
    pointer-events: none;
    transform: translateY(-50%);
  }
}
