// Quotation
//
// An accent block for quotes, mostly used within the two-column wrapper. Can also be used outside the wrapper as a full-width element.  Gets a $basePrimary background in all sections.
//
// Fields: `body`, `attribution`
//
// Markup: kss-markup/quotation.html
//
// Weight: 3
//
// Styleguide Content-Elements.Quotation

.quotation {
  @include thinGlyphs;
  color: $neutralWhite;

  p {
    font-style: italic;
    @include fluidType(18px, 14px, $break70);

    @include respond($break70) {
      line-height: 1.4;
      text-align: center;
    }
  }

  figcaption {
    margin-top: 28px;
    text-align: right;

    @include respond($break70) {
      margin-top: 18px;
      text-align: center;
    }
  }

  cite {
    @include clippedBox;
    @include fluidType(15px, 12px, $break70);
    display: inline-block;
    font-style: normal;
    font-weight: $black;
    text-transform: uppercase;
    background: white;

    @include respond($break70) {
      padding: 8px 8px 2px;
    }

    &.cite-0 {
      color: $basePrimary;
    }
  
    &.cite-1 {
      color: #{map-get($accentDark, 1)};
    }
  
    &.cite-2 {
      color: $basePrimaryDark;
    }
  
    &.cite-3 {
      color: #{map-get($accentDark, 3)};
    }
  
    &.cite-4 {
      color: #{map-get($accentDark, 4)};
    }
  }
}
