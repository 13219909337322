.card {
  @include linkUnstyled;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 10px rgba($neutralBlack, 0.05);
  transition: color 0.2s 0.05s $timing;

  &:hover:not(.no-background) {
    .body {
      color: $neutralWhite;
      transition-delay: 0.1s;
    }

    article::before {
      transition-delay: 0s;
      transform: translateY(0);
    }

    h3 {
      color: $neutralWhite;
      transition-delay: 0.15s;
    }

    .button-primary {
      color: var(--colorPrimary);

      &::before {
        background-color: $neutralWhite;
        transition-delay: 0.05s;
      }
    }
  }

  figure {
    background-color: $neutral40;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  article {
    position: relative;
    flex-grow: 1;
    padding: $containerPadding20;
    overflow: hidden;
    background-color: $neutralWhite;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: var(--colorPrimary);
      transition: transform 0.3s $timing;
      transform: translateY(100%);
    }
  }

  h3 {
    overflow-wrap: break-word;
    transition: color 0.2s $timing;
  }
}
