// Link Cards
//
// A grid of up to 4 simpler callouts with image and title wrapped in an anchor.
// Styled primarily through the shared `.card` class and wrapped in the shared
// `.card-wrapper` class. Additional styles set in `.link-card`. Switches to a
// slider on mobile through wrapping in a `.slider-wrapper` div and `data-behavior="SlideCards"`.
//
// Fields: repeater of `cards` containing the following fields:
// * `image`
// * `alt`
// * `url`
// * `link_text`
//
// Markup: kss-markup/link-cards.html
//
// Weight: 9
//
// Styleguide Content-Elements.LinkCards
.link-card {
  figure {
    height: 172px;
  }

  article {
    &::before {
      background-color: map-get($accent, 4);
    }
  }

  h3 {
    @include fluidType(30px, 24px);
    color: map-get($accent, 4);
    text-decoration: underline;
  }
}
