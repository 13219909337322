// CE container layout classes
// --------------------------------------------------------
.content-section {
  padding-top: $containerPadding75;
  padding-bottom: $containerPadding75;
  overflow: hidden;

  @include respond($break130) {
    padding-top: 5.769vw;
    padding-bottom: 5.769vw;
  }

  @include respond($break50) {
    padding-top: $containerPadding40;
    padding-bottom: $containerPadding40;
  }

  &.no-top-padding {
    @include respond($break90) {
      padding-top: 0;
    }
  }

  &.reduce-bottom-padding {
    padding-bottom: 40px;
  }

  &.bg-alert {
    padding: 40px;
    background-color: $baseSecondary;
  }

  &.bg-gray,
  &.bg-primary {
    margin-top: $containerPadding75;
    background-color: $neutral20;

    @include respond($break130) {
      margin-top: 5.769vw;
    }

    @include respond($break50) {
      margin-top: $containerPadding40;
    }

    + .bg-gray,
    &:first-child {
      margin-top: 0;
    }
  }

  &.bg-white {
    + .content-section {
      &.bg-white {
        padding-top: 0;
      }

      &.bg-gray,
      &.bg-primary {
        margin-top: 0;
      }
    }
  }

  &.bg-primary,
  &.bg-purple,
  &.bg-light-blue,
  &.bg-green,
  &.bg-navy {
    padding: $containerPadding40 0;
    overflow: hidden;

    @include respond($break130) {
      padding: 3.125vw 0;
    }

    @include respond($break70) {
      padding: $containerPadding20 0;
    }

    &.padding-large {
      padding: $containerPadding75 0;

      @include respond($break130) {
        padding: 5.769vw 0;
      }

      @include respond($break50) {
        padding: $containerPadding40 0;
      }
    }
  }

  &.bg-primary {
    background: $basePrimaryDark;
  }

  &.bg-purple {
    background: #{map-get($accentDark, 1)};
  }

  &.bg-light-blue {
    background: $basePrimary;
  }

  &.bg-green {
    background: #{map-get($accentDark, 3)};
  }

  &.bg-navy {
    background: #{map-get($accentDark, 4)};
  }

  &.bg-gray {
    + .bg-primary {
      margin-top: 0;
    }

    + .bg-gray {
      padding-top: 0;
    }
  }

  &.full-bleed {
    padding: 0;
  }

  &.bg-accent-1 {
    + .bg-gray {
      margin-top: 0;
    }
  }
}

.content-section-nested {
  > .container-primary {
    padding-right: 0;
    padding-left: 0;
  }

  + .content-section-nested {
    margin-top: 32px;

    @include respond($break70) {
      margin-top: 5.769vw;
    }
  }

  &.bg-primary,
  &.bg-purple,
  &.bg-light-blue,
  &.bg-green,
  &.bg-navy {
    padding: $containerPadding40;
    margin-top: 0;

    @include respond($break70) {
      padding: $containerPadding20 5.86vw;
      margin: 0 -5.86vw;
    }

    @include respond($break50) {
      padding: $containerPadding20;
      margin: 0 -20px;
    }
  }

  &.bg-primary {
    background: $basePrimaryDark;
  }

  &.bg-purple {
    background: #{map-get($accentDark, 1)};
  }

  &.bg-light-blue {
    background: $basePrimary;
  }

  &.bg-green {
    background: #{map-get($accentDark, 3)};
  }

  &.bg-navy {
    background: #{map-get($accentDark, 4)};
  }
}

.content-section,
.hero {
  &:last-child:not(.footer-align) {
    margin-bottom: 110px;

    @include respond($break130) {
      margin-bottom: 8.594vw;
    }

    @include respond($break70) {
      margin-bottom: 68px;
    }
  }
}

.standalone-heading {
  padding-bottom: 0;
}

// Text
//
// A basic content element for RTE text. Can be nested in a two-column wrapper.
//
// Fields: `background_color`, `heading`, `body`
//
// Markup: kss-markup/text.html
//
// Weight: 1
//
// Styleguide Content-Elements.Text

// Two Columns
//
// A wrapper element that can hold Text, Quotation, and Video CEs. Column widths may be 1:1 or 2:1.
//
// Fields: `column_width`, `heading`, `background_color`
//
// Markup: kss-markup/two-column.html
//
// Weight: 2
//
// Styleguide Content-Elements.TwoColumns
.columns {
  display: flex;
  justify-content: space-between;

  // break email addresses so that long ones don't interfere with column layouts or overflow on mobile
  a[href^="mailto:"] {
    word-break: break-all;
  }

  @include respond($break70) {
    flex-direction: column;
  }

  .column {
    flex: 1;
    min-width: 0;

    @include respond($break70) {
      flex: 0 auto;
    }

    + .column {
      margin-left: $containerPadding75;

      [dir="rtl"] & {
        margin-right: $containerPadding75;
        margin-left: 0;
      }

      @include respond($break130) {
        margin-left: 6.637vw;

        [dir="rtl"] & {
          margin-right: 6.637vw;
          margin-left: 0;
        }
      }

      @include respond($break70) {
        padding-top: 5.769vw;
        margin-left: 0;

        [dir="rtl"] & {
          margin-right: 0;
        }
      }

      @include respond($break50) {
        padding-top: $containerPadding40;
      }
    }

    &.width-67 {
      flex-grow: 2;
    }
  }
}
