// Callout Cards
//
// A grid of up to 4 callouts with image and copy wrapped in an anchor. Styled
// primarily through the shared `.card` class and wrapped in the shared
// `.card-wrapper` class. Additional styles set in `.callout-card`. Switches to a
// slider on mobile through wrapping in a `.slider-wrapper` div and `data-behavior="SlideCards"`.
//
// Fields: repeater of `cards` containing the following fields:
// * `accent_color`: select one of the four site section colors
// * `image`
// * `alt`
// * `heading`
// * `body`
// * `url`
// * `link_text`
//
// Markup: kss-markup/callout-cards.html
//
// Weight: 10
//
// Styleguide Content-Elements.CalloutCards
.callout-card {
  figure {
    position: relative;
    height: 233px;

    &::after {
      @include clippedBox(33px, 33px);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 92%;
      content: '';
      background-color: var(--colorPrimary);
      transform: translateY(50%);
    }
  }

  article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .body {
      flex-grow: 1;
      color: $neutral90;
    }
  }

  header {
    margin-bottom: $containerPadding15;
  }

  p {
    @include lineHeightSmall;
  }

  h3 {
    @include fluidType(28px, 23px);
    color: var(--colorPrimaryDark);
  }

  .button-wrapper {
    margin-top: $containerPadding20;
  }

  .button-primary {
    transition-delay: 0.1s;
  }
}
