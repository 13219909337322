.post-thumb {
  display: flex;
  flex-direction: column;

  &__header {
    margin-top: 1.75em;
  }

  &__title {
    @include headingTertiary;
  }

  &__time {
    @include copyPrimary;
    display: block;
    margin-top: 0.333em;
  }

  &__thumb {
    position: relative;
    order: -1;
    overflow: hidden;
    background-color: var(--colorPrimary);

    &::after {
      @include clippedBox(42px, 42px);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 92%;
      content: '';
      background-color: var(--colorSecondary);
      transform: translateY(50%);
    }
  }

  &__image {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  &__teaser {
    @include copyPrimary;
    margin-top: 1.722em;
  }

  &__link {
    @include hoverFade;
    display: block;

    &--read-more {
      @include linkPrimary;
      display: inline-block;
      margin-top: 1.278em;
      font-size: 1.125em;
      color: var(--colorSecondary);
    }
  }
}
