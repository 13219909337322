/* Wraps `.card-wrapper` to make it a slider on small screens */
.slider-wrapper {
  position: relative;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }

  .card-wrapper {
    // never use slider transform on larger screens
    @include respond($break60, min) {
      transform: translateX(0%) !important; // stylelint-disable-line declaration-no-important
    }

    // switch to slider on small screens
    @include respond($break60) {
      display: flex;
      flex-wrap: nowrap;

      .card {
        flex: 0 0 100%;
        margin-right: 5.86vw;

        + .card {
          margin-left: 5.86vw;
        }
      }
    }

    @include respond($break50) {
      .card {
        margin-right: 20px;

        + .card {
          margin-left: 20px;
        }
      }
    }
  }

  .prev-next {
    $toggleSize: (
      width: 8.61vw,
      max-width: 35px,
      height: 13.71vw,
      max-height: 56px
    );

    @include listUnstyled;
    display: none;

    @include respond($break60) {
      display: block;
    }

    li {
      @include hoverFade;
      position: absolute;
      top: 50%;
      z-index: 50;
      width: map-get($toggleSize, width);
      max-width: map-get($toggleSize, max-width);
      height: map-get($toggleSize, height);
      max-height: map-get($toggleSize, max-height);
      color: $neutralWhite;
      background-color: $baseSecondary;
      transform: translateY(-50%);

      &:first-child {
        left: 0;

        .icon {
          transform: rotate(90deg);
        }
      }

      &:last-child {
        right: 0;

        .icon {
          transform: rotate(-90deg);
        }
      }
    }

    button {
      @include buttonUnstyled;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .icon {
      width: map-get($toggleSize, width);
      max-width: map-get($toggleSize, max-width);
      height: map-get($toggleSize, width);
      max-height: map-get($toggleSize, max-width);
    }
  }
}
