// Component Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)

// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons (like the size of an icon-font glyph)

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Basic utility patterns
// --------------------------------------------------------
// Visually Hidden, for skip links and screen-reader only elements
@mixin screenReaderText {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

.screen-reader-text {
  @include screenReaderText;
}

// Image replacement, for icons and things with hidden accessibility text
@mixin imageReplacement {
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;

  br {
    display: none;
  }
}

@mixin focusDefault {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline-width: 0;
  }

  &.focus-visible {
    outline: auto 4px;
  }
}

// Utility
// --------------------------------------------------------
@mixin linkUnstyled {
  color: inherit;
  text-decoration: none;
}

@mixin hoverFade {
  transition: opacity 0.2s $timing;

  &:hover {
    opacity: 0.7;
  }
}

@mixin transitionFade($duration: 0.2s, $activeClass: "active") {
  visibility: hidden;
  opacity: 0;
  transition: opacity $duration $timing, visibility $duration $timing;

  &.#{$activeClass} {
    visibility: visible;
    opacity: 1;
  }
}

@mixin clippedBox($paddingPrimary: $containerPadding15, $paddingBottom: 10px) {
  padding: $paddingPrimary;

  @supports (clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%)) or
    (-webkit-clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%)) {
    padding-bottom: $paddingBottom;
    clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%);
  }
}

@mixin boxShadow($property: "box-shadow", $opacity: 0.16) {
  @if ($property == "filter") {
    filter: drop-shadow(0 3px 6px rgba($neutralBlack, $opacity));
  } @else {
    box-shadow: 0 3px 6px rgba($neutralBlack, $opacity);
  }
}

// Inline SVG Icon
//
// Displays an inline SVG that inherits size and fill color from its parent.
//
// Use the `site/icon` partial to reference an SVG `<symbol>` in our sprite sheet.
//
// $size - Pass an argument to `width` and `height` using the mixin: `@include icon($size)`. Defaults to `1em`.
//
// Available icons:
// * caret
// * caret-light
// * caret-bold
// * close
// * plus
// * play
// * search
// * search-light
// * facebook
// * twitter
// * youtube
//
// Markup: kss-markup/svg-icon.html
//
// .icon-large - An icon with a width and height of 2em.
// .icon-x-large - An icon with a width and height of 3em.
//
// Styleguide STACSS.Appearance.SvgIcon

@mixin icon($size: 1em) {
  display: inline;
  width: $size;
  height: $size;
  vertical-align: middle;
  fill: currentColor;
}

.icon {
  @include icon;
}

.icon-large {
  @include icon(2em);
}

.icon-x-large {
  @include icon(3em);
}

// Buttons
// --------------------------------------------------------

// Primary Button
//
// A primary `<button>` or `<a>` block. Can be called using
// `@include buttonPrimary` or `.button-primary`. Includes a clipping effect and
// shadow using `@include clippedBox`. Due to `box-shadow` not working with `clip-path`,
// the shadow is applied as a `filter` to the parent container. By default,
// Primary Button inherits a background-color from the color theme of the page it appears on.
//
// $bg-color - Background color of the button. Defaults to `inherit`.
//
// Markup:
// <button class="button-primary">Button Element</button>
// <a class="button-primary">Link Button</a>
//
// :hover
//
// Weight: 4
//
// Styleguide STACSS.Appearance.PrimaryButton

@mixin buttonPrimary($bg-color: var(--colorPrimary)) {
  @include thinGlyphs;
  @include buttonUnstyled;
  @include boxShadow("filter");
  position: relative;
  z-index: 5;
  display: inline-block;
  padding: $containerPadding15;
  font-size: 15px;
  font-weight: $black;
  color: $neutralWhite;
  text-transform: uppercase;

  @supports (clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%)) or
    (-webkit-clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%)) {
    padding-bottom: 10px;
  }

  &::before {
    @include clippedBox;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -25;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: $bg-color;
    // transition: background-color 0.2s $timing;
  }
}

.button-primary {
  @include buttonPrimary;
}

a.button-primary {
  @include hoverFade;
}

@mixin buttonSecondary($bg-color: var(--colorPrimaryDark)) {
  @include thinGlyphs;
  @include buttonUnstyled;
  position: relative;
  display: inline-block;
  padding: 0.6em 0.667em 0.4em;
  font-weight: $bold;
  line-height: 1;
  color: $neutralWhite;
  text-transform: uppercase;
  background-color: $bg-color;
}

.button-secondary {
  @include buttonSecondary;
}

a.button-secondary {
  @include hoverFade;
}

.label-primary {
  @include thinGlyphs;
  @include fluidType(18px, 16px);
  display: block;
  font-weight: $black;
  color: $neutralWhite;
}

.input-primary {
  @include boxShadow("filter");
  display: block;
  width: 220px;
  height: 42px;
  padding: 5px 0 0 10px;
  border: none;
  outline: 0;

  @include respond($break90) {
    width: 23.15vw;
  }

  @include respond($break70) {
    width: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

// Shared Components
// --------------------------------------------------------

// Dropdown Select
//
// A dropdown `<select>` element using native select UI.
//
// Markup: kss-markup/dropdown.html
//
// Weight: 5
//
// Styleguide STACSS.Appearance.Dropdown

.dropdown {
  position: relative;
  display: inline-block;
  background-color: $formBgColor;

  select {
    @include inputUnstyled;
    height: 28px;
    padding: 6px 22px 6px 8px;
    text-transform: uppercase;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }

  .icon {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 17px;
    height: 17px;
    pointer-events: none;
  }
}

// Content Accordion
//
// A component that expands when toggled to reveal hidden content. Appears in FAQs and mobile site navigation submenus.
//
// Usage: `@include accordion($color)` or `.accordion` in SCSS and `data-behavior="ExpandAccordion"` in markup.
//
// $color: Sets `color` for the toggle. Defaults to `$basePrimary`.
//
// Markup: kss-markup/content-accordion.html
//
// Weight: 6
//
// Styleguide STACSS.Appearance.ContentAccordion

@mixin accordion($color: var(--colorPrimaryDark)) {
  transition: max-height 0.25s $timing;

  [data-ref="accordionToggle"] {
    @include buttonUnstyled;
    @include hoverFade;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    color: $color;
    cursor: pointer;

    &:focus {
      outline: none;

      .icon {
        outline: 2px dotted;
      }
    }

    span {
      padding-top: 8px;
    }

    .icon {
      width: 1.39em;
      height: 1.39em;

      rect {
        opacity: 1;
        transition: opacity 0.2s $timing;
      }
    }
  }

  [data-ref="fullContent"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s 0.3s $timing, visibility 0.2s 0.3s $timing;
  }

  &.open {
    [data-ref="accordionToggle"] {
      .icon {
        rect:first-child {
          opacity: 0;
        }
      }
    }

    [data-ref="fullContent"] {
      visibility: visible;
      opacity: 1;
    }
  }

  &.closed {
    transition-delay: 0.15s;

    [data-ref="accordionToggle"] {
      .icon {
        rect {
          transition-delay: 0.15s;
        }
      }
    }

    [data-ref="fullContent"] {
      transition: opacity 0.1s 0s $timing, visibility 0.1s 0s $timing;
    }
  }
}

.accordion {
  @include accordion;
}

// Accordion Menu
//
// Similar to Content Accordion, with overrides to reveal an absolutely-positioned dropdown menu. Appears in mobile hero "Quick Links" menu.
//
// Usage: `@include accordionMenu($color)` or `.accordion-menu` in SCSS and `data-behavior="ExpandAccordion"` in markup.
//
// $color: Sets `color` for the toggle. Defaults to `$basePrimary`.
//
// Markup: kss-markup/menu-accordion.html
//
// Weight: 7
//
// Styleguide STACSS.Appearance.AccordionMenu

@mixin accordionMenu($color: $neutralWhite) {
  @include accordion($color);
  @include boxShadow;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 47px;
  font-weight: $black;
  background: linear-gradient(
      rgba($neutralWhite, 0.75),
      rgba($neutralWhite, 0.75)
    ),
    linear-gradient(var(--colorPrimary), var(--colorPrimary));

  [data-ref="accordionToggle"] {
    @include thinGlyphs;
    width: 100%;
    background-color: var(--colorPrimary);

    span {
      padding-top: 3px;
      line-height: 1;
    }

    .toggle-icon {
      position: relative;
      width: 1.39em;
      height: 1.39em;
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &.closed {
    [data-ref="accordionToggle"] .icon.caret {
      opacity: 1;
    }
  }

  &.open {
    [data-ref="accordionToggle"] .icon.close {
      opacity: 1;
    }
  }

  [data-ref="fullContent"] {
    @include listUnstyled;
    @include fluidType(17px, 14px, $break90);

    li {
      padding: 0;
      text-indent: 0;

      &::before {
        display: none;
      }
    }

    a {
      display: block;
      width: 100%;
      padding-top: $containerPadding20 * 0.5;
      padding-bottom: $containerPadding20 * 0.5;
      color: var(--colorPrimary);
      text-decoration: none;
    }
  }

  [data-ref="accordionToggle"],
  [data-ref="fullContent"] {
    padding: 12px 15px;

    @include respond($break90) {
      padding: 1.263vw 1.58vw;
    }

    @include respond($break60) {
      padding: 12px $containerPadding15;
    }
  }
}

.accordion-menu {
  @include accordionMenu;
}

.invisible {
  display: none;
}

.bg-accent-1 {
  background-color: map-get($accentDark, 1);
}
