// Base mixins
// ---------

// Clearfix
// $mode accepts 'light' or 'heavy'
// 'light' is sufficient for clearing floats, while 'heavy' is required to
// prevent margin collapsing
// --------------------------
@mixin clearfix($mode: light) {
  @if $mode == heavy {
    &::before,
    &::after {
      display: table;
      content: " ";
    }

    &::after {
      clear: both;
    }
  } @else {
    &::after {
      display: block;
      clear: both;
      content: " ";
    }
  }
}

.clearfix {
  @include clearfix;
}

@mixin thinGlyphs {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Lists
// --------------------------------------------------------
// Unstyled List
// --------------------------
@mixin listUnstyled {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

// Horizontal List
// --------------------------
@mixin listHorizontal {
  @include listUnstyled;

  li {
    display: inline-block;
  }
}

// Links, Buttons
// --------------------------------------------------------
@mixin buttonUnstyled {
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
}

// Prevents user from accidentally selecting text
// on click, helpful for labels
@mixin noSelect {
  // Ignore vendor prefixing as this is a pre-standards parameter
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* stylelint-enable property-no-vendor-prefix */
}

// Form Elements
// --------------------------------------------------------
@mixin inputUnstyled {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
}

// Fluid Elements
// --------------------------------------------------------
// return fluid-scale calc value
@function fluid-scale-calc(
  $max-value,
  $min-value,
  $max-vw: $break130,
  $min-vw: $break50
) {
  @return calc(
    #{$min-value} + #{strip-unit($max-value - $min-value)} *
      (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}
  );
}

// Set min and max sizes and breakpoints and let SCSS fluidly scale different properties in-between

// Adapted from https://www.sassmeister.com/gist/7f22e44ace49b5124eec
// Usage: @include fluid-type(font-size, 22px, 16px, break(130), break(50));
@mixin fluid-scale(
  $properties,
  $max-value,
  $min-value,
  $max-vw: $break130,
  $min-vw: $break50
) {
  @each $property in $properties {
    #{$property}: $max-value;
  }

  @media (max-width: $max-vw) {
    @each $property in $properties {
      #{$property}: fluid-scale-calc($max-value, $min-value, $max-vw, $min-vw);
    }
  }

  @media (max-width: $min-vw) {
    @each $property in $properties {
      #{$property}: $min-value;
    }
  }
}

// Focus State
// --------------------------------------------------------
@mixin focusState {
  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline: 3px solid $basePrimary;
    border-radius: 2px;
  }
}
