.search {
  form {
    input {
      @include inputUnstyled;

      &::placeholder {
        color: inherit;
      }
    }

    .submit {
      @include buttonUnstyled;
    }
  }

  &--desktop-nav {
    @include respond($break90) {
      display: none;
    }

    form {
      $inputPadding: 2px 6px;

      display: flex;
      background-color: $formBgColor;

      input {
        height: 23px;
        padding: $inputPadding;
        margin-top: 5px;
      }

      .submit {
        padding: $inputPadding;

        .icon {
          width: 1.25em;
          height: 1.25em;
        }
      }
    }
  }

  &--mobile-nav,
  &--mobile-nav-submenu {
    margin-top: 4em;

    form {
      display: flex;
      border-bottom: 1px solid;

      input {
        @include thinGlyphs;
        flex-grow: 1;
        padding: $containerPadding20 0 0;
        font-size: 16px;
        font-weight: $book;
        text-transform: uppercase;
      }

      .submit {
        flex-shrink: 0;
        order: -1;
        width: auto;
        padding-right: 0.5em;
        padding-bottom: 0.222em;

        .icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &__icon {
    &--light {
      @include respond($break90) {
        display: none;
      }
    }

    &--normal {
      display: none;

      @include respond($break90) {
        display: block;
      }
    }
  }
}
