/*
Declare any selectors below which are impacted by `.palette-` colors.
No need to declare properties for each `.palette-` class separately.

Nested CSS variables must be consumed in the same selector in which they are
declared to work with PostCSS. So as dumb as the below looks, it's necessary
to create backwards compatibility for CSS variables.

See https://github.com/MadLittleMods/postcss-css-variables#caveats
*/

// Common selectors that inherit colors from `main.palette-`
main {
  &.palette-1,
  &.palette-2,
  &.palette-3,
  &.palette-4 {
    .content-rte,
    .content-default {
      > h1 {
        color: var(--colorSecondary);
      }

      > h2,
      > header h2,
      > h3,
      > h4,
      > h5 {
        color: var(--colorSecondaryDark);
      }

      .button-primary::before {
        background-color: var(--colorPrimaryDark);
      }
    }

    .block-cta {
      h5 {
        color: var(--colorSecondaryDark);
      }
    }

    .faq-accordion {
      button {
        color: var(--colorPrimaryDark);
      }

      .table-heading {
        color: var(--colorPrimaryDark);
        border-top: 2px solid var(--colorPrimary);
        border-bottom: 2px solid var(--colorPrimary);
      }
    }

    .hero,
    .hero-content:not(.transparent),
    h1 {
      background-color: var(--colorPrimaryDark);

      @include respond($break90) {
        nav {
          background: linear-gradient(
              rgba($neutralWhite, 0.75),
              rgba($neutralWhite, 0.75)
            ),
            linear-gradient(var(--colorPrimary), var(--colorPrimary));

          a {
            color: var(--colorPrimary);
          }

          button {
            background-color: var(--colorPrimary);
          }
        }
      }
    }

    .three-column {
      h2,
      h4 {
        color: var(--colorSecondaryDark);
      }
    }

    .rae-grid {
      figure {
        background-color: var(--colorPrimary);
      }

      article::before {
        background-color: var(--colorPrimary);
      }
    }

    .post-thumb {
      &__thumb {
        background-color: var(--colorPrimary);
      }

      &__thumb::after {
        background-color: var(--colorSecondary);
      }

      &__title,
      &__link--read-more {
        color: var(--colorSecondary);
      }
    }

    .single-post {
      &__figure {
        background-color: var(--colorPrimary);
      }

      &__teaser,
      &__author-name {
        color: var(--colorSecondary);
      }
    }

    .post-pagination {
      &__link {
        color: var(--colorSecondary);
      }
    }
  }
}

// Elements that themselves can take `.palette-` classes
.callout-card {
  &.palette-1,
  &.palette-2,
  &.palette-3,
  &.palette-4 {
    article::before,
    figure::after,
    &:not(:hover) .button-primary::before {
      background-color: var(--colorPrimaryDark);
    }

    &:not(:hover) h3,
    &:hover .button-primary {
      color: var(--colorPrimaryDark);
    }
  }
}

.desktop-site-navigation .level-1 > li {
  &.palette-1,
  &.palette-2,
  &.palette-3,
  &.palette-4 {
    > a.active {
      color: var(--colorPrimaryDark);
    }

    .level-2 {
      background-color: var(--colorPrimaryDark);
    }
  }
}

.mobile-site-navigation .level-2 {
  &.palette-1,
  &.palette-2,
  &.palette-3,
  &.palette-4 {
    background-color: var(--colorPrimary);

    .submenu-close {
      background-color: var(--colorPrimary);
    }
  }
}
