@use "sass:math";

.tableau-tabs {
  $_container-size: $containerBaseWidth;
  $_max-icons-visible: 6;
  $_icon-size: 124px;

  margin-right: auto;
  margin-left: auto;
  text-align: center;

  // enable Flickity by default
  &::after {
    display: none;
    content: 'flickity';
  }

  @include respond($break60) {
    display: none;
    // disable Flickity
    &::after {
      content: '';
    }
  }

  @for $i from 1 through $_max-icons-visible {
    &--#{$i} {
      max-width: math.div($_container-size * ($i + 1), $_max-icons-visible);

      .tableau-tabs__tab {
        width: percentage(math.div(1, $i));
      }
    }
  }

  &.show-nav-buttons {
    .flickity-prev-next-button {
      display: block;
      opacity: 1;
    }
  }

  .flickity-viewport {
    min-height: 155px;
  }

  .flickity-prev-next-button {
    $_button-size: 44px;
    $_button-color: #405866;

    @include hoverFade;
    top: $_icon-size * 0.5;
    display: none;
    color: $_button-color;
    background-color: transparent;
    opacity: 0;
    transition: opacity 0.2s;

    &.previous {
      left: -1 * $_button-size;
    }

    &.next {
      right: -1 * $_button-size;
    }
  }

  &__tab {
    @include buttonUnstyled;
    @include focusDefault;
    @include hoverFade;
    min-width: calc(#{$_icon-size} + 4%);

    &:focus-visible {
      outline: 2px solid;
    }

    & + & {
      // margin-left: 4%;
    }
  }

  &__icon {
    width: $_icon-size;
    height: auto;
  }

  &__title {
    margin-top: 5px;
    font-size: 18px;
    font-weight: $bold;
    color: $neutral60;
  }
}
