.immunization-intro {
  h2 {
    color: map-get($accent, 3);
  }

  @include respond($containerBaseWidth) {
    background-color: $neutral20;
  }

  @media print {
    display: none;
  }
}
