@mixin fullBleedSlide($bgColor: var(--colorPrimary)) {
  @include containerPrimary;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  padding-top: 90px;
  padding-bottom: $containerPadding20;
  background-color: $bgColor;
  transform: translateX(100%);

  @include respond($break90) {
    display: block;
    transition: transform 0.5s $timing;

    &.open {
      transform: translateX(0);
    }
  }
}

.mobile-site-navigation {
  @include fullBleedSlide;
  overflow: hidden;
  color: $neutralWhite;

  ul {
    @include listUnstyled;
    font-size: 18px;
  }

  li {
    border-bottom: 1px solid;
  }

  button {
    @include buttonUnstyled;
  }

  a {
    @include linkUnstyled;
    display: block;
  }

  button, a {
    @include buttonUnstyled;
    @include hoverFade;
    width: 100%;
    padding: $containerPadding20 0 12px;
    text-align: left;
  }

  .level-1 {
    @include listUnstyled;
    @include thinGlyphs;
    font-weight: $black;

    > li {
      > button, > a {
        text-transform: uppercase;
      }
    }
  }

  .level-2 {
    @include fullBleedSlide(map-get($accent, 1));
    @include thinGlyphs;
    font-weight: $bold;
    background-color: var(--colorPrimary);

    .scroll-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 45px;
      overflow: scroll;

      > ul {
        flex-grow: 1;

        > li {
          @include accordion($neutralWhite);
        }
      }
    }

    .child-link-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      .child-link {
        padding:  8px 0 0;
      }

      [data-ref='accordionToggle'] {
        &.child-accordion {
          padding: 16px 0;
        }
      }
    }

    .submenu-close {
      @include thinGlyphs;
      position: absolute;
      bottom: 0;
      width: 88.15vw;
      padding: 5px 0 18px;
      margin-top: $containerPadding40;
      font-weight: $black;
      line-height: 1;
      text-transform: uppercase;
      background-color: var(--colorPrimary);
      border-top: 4px solid;

      @include respond($break50) {
        width: calc(100% - 40px);
      }

      .icon {
        width: 1.39em;
        height: 1.39em;
        transform: rotate(90deg);
      }
    }
  }

  ul.level-3 {
    font-weight: $book;

    li {
      border-bottom: none;

      a {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &:last-child a {
        padding-bottom: 20px;
      }
    }
  }
}
