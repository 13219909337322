@use "sass:math";

.fact-callout-group {
  $_container-size: $containerBaseWidth;
  $_default-min-width: 250px;
  $_default-max-width: 382px;
  $_default-image-max-width: 300px;
  $_gap: 35px;
  $_max-columns: 4;
  $_mobile-breakpoint: 627px;

  color: $neutralWhite;

  @include respond($break50) {
    text-align: center;
  }

  &__heading {
    @include fluid-scale(margin-top, 25px, 0);
    @include headingSecondary;
    color: inherit;
    text-align: left;
  }

  &__subheading {
    @include fluid-scale(margin-top, 89px, $_gap);
    @include headingTertiary;
    @include fluidType(30px, 18px);
    margin-bottom: $_gap;
    color: inherit;

    @include respond($break50 + 1, min) {
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  &__subgroup {
    @for $i from 2 through $_max-columns {
      &--#{$i} {
        .fact-callout-group__callout {
          flex-basis: calc(#{percentage(math.div(1, $i))} - #{$_gap});
          max-width: math.div($_container-size, $i);
        }
      }
    }

    &--2 {
      .fact-callout-group__image {
        max-width: $_container-size * 0.5 - 182px;
      }
    }

    &--3 {
      .fact-callout-group__image {
        max-width: math.div($_container-size, 3) - 75px;
      }
    }

    &--4 {
      .fact-callout-group__image {
        max-width: $_container-size * 0.25 - 75px;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-top: 0;
    margin-left: -1 * $_gap;

    @include respond($break50) {
      margin-top: -0.5 * $_gap;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__callout {
    flex-basis: calc(#{percentage(math.div(1, $_max-columns))} - #{$_gap});
    flex-grow: 1;
    min-width: $_default-min-width;
    max-width: $_default-max-width;
    margin-top: $_gap;
    margin-left: $_gap;

    @include respond($break50) {
      margin-top: 0.5 * $_gap;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__image {
    display: block;
    width: 100%;
    max-width: $_default-image-max-width;
    height: auto;

    @include respond($break50) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__large-text {
    @include fluid-scale(font-size, 40px, 22px);
    @include fluid-scale(margin-top, 34px, 18px);
    display: block;
    line-height: 1.25;
  }

  &__small-text {
    display: block;
  }
}
