.site-footer {
  .footer-contact {
    background: $basePrimaryDark;
  }

  .footer-main {
    background-color: map-get($accent, 4);
  }

  .contact {
    @include containerPrimary;
    position: relative;
    padding-top: $containerPadding75;
    padding-bottom: 68px;
    color: white;

    @include respond($break130) {
      padding-top: 5.896vw;
      padding-bottom: 5.312vw;
    }

    @include respond($break70) {
      padding-top: 50px;
      padding-bottom: 40px;
    }

    .responsive-break {
      @include respond($break115) {
        display: none;
      }
    }

    .clipped-box {
      @include clippedBox($containerPadding25, 15px);
      position: absolute;
      top: 0;
      display: inline-block;
      background: map-get($accent, 1);
      transform: translateY(-50%);

      .heading-secondary {
        @include hoverFade;
        line-height: 1;
        color: $neutralWhite;

        @include respond($break70) {
          font-size: 24px;
        }
      }
    }

    .contact-content {
      display: flex;

      .text {
        flex: 75%;

        @include respond($break70) {
          flex: 80%;
        }

        @include respond($break60) {
          flex: 100%;
        }
      }

      .illustration {
        position: relative;
        flex: 25%;
        text-align: right;

        @include respond($break70) {
          flex: 20%;
        }

        @include respond($break60) {
          display: none;
        }

        .telephone {
          position: absolute;
          right: 0;
          bottom: 0;

          img {
            width: 134px;
            height: 134px;

            // stylelint-disable max-nesting-depth
            @include respond($break70) {
              width: 13vw;
              padding-right: 10px;
            }
            // stylelint-enable max-nesting-depth
          }
        }
      }

      p {
        @include copyPrimary;
        line-height: 2;

        @include respond($break70) {
          font-size: 16px;
          line-height: 1.6;
        }

        &.accent {
          @include thinGlyphs;
          font-weight: $black;
        }

        + p {
          margin-top: 0;
        }

        a {
          @include linkPrimary;
          @include hoverFade;
          padding-top: 3px;
        }
      }
    }
  }

  a[href^='mailto:'] {
    word-break: break-all;
  }

  .container-primary {
    padding-top: $containerPadding75;
    padding-bottom: $containerPadding75;
    font-size: 12px;
    color: $neutralWhite;

    @include respond($break130) {
      padding-top: 5.769vw;
      padding-bottom: 5.769vw;
    }

    @include respond($break100) {
      padding-top: $containerPadding25;
      padding-bottom: $containerPadding25;
    }

    a {
      @include linkPrimary;
      @include hoverFade;
    }
  }

  &__nav-container {
    display: flex;
    justify-content: flex-start;

    @include respond($break100) {
      flex-direction: column;
    }
  }

  ul {
    @include listUnstyled;
  }

  .header-mobile {
    display: none;

    @include respond($break100) {
      display: flex;
      order: 1;
      margin-bottom: 35px;

      .logo {
        @include hoverFade;
        flex: 50%;

        img {
          width: 60px;
        }
      }

      .social-navigation {
        &.mobile {
          display: inline-block;
          flex: 50%;
          text-align: right;

          ul {
            @include listHorizontal;
          }

          .icon {
            width: 32px;
            height: 32px;

            // stylelint-disable max-nesting-depth
            &.hidden-mobile {
              display: none;
            }
            // stylelint-enable max-nesting-depth
          }
        }
      }
    }
  }

  .colophon {
    @include respond($break100) {
      order: 3;
    }

    .logo {
      @include hoverFade;
      padding: 0 $containerPadding75 $containerPadding75 0;

      @include respond($break130) {
        padding-right: 5.8vw;
      }

      @include respond($break100) {
        display: none;
      }

      img {
        width: 128px;
        height: 103.05px;

        @include respond($break110) {
          width: 11.6vw;
        }
      }
    }

    .site-navigation {
      padding-bottom: 15px;

      @include respond($break100) {
        padding-bottom: 10px;

        ul {
          @include listHorizontal;
        }
      }

      header {
        .heading-quinary {
          @include respond($break100) {
            padding-bottom: 0;
            line-height: 1.5;
          }

          a {
            font-weight: $black;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }

      li {
        a {
          font-weight: $light;

          .divider {
            display: none;
          }

          // stylelint-disable max-nesting-depth
          @include respond($break100) {
            text-decoration: none;

            .divider {
              display: inline-block;
              margin: 0 8px;
            }
          }
          // stylelint-enable max-nesting-depth
        }
      }
    }

    .copyright {
      font-weight: $light;
    }
  }

  .site-navigation {
    @include respond($break100) {
      order: 2;
    }

    .nav-list {
      display: flex;
      justify-content: space-between;

      &--jcfs {
        justify-content: flex-start;
        padding-bottom: 10px;
      }

      @include respond($break100) {
        flex-direction: column;
        margin-bottom: 22px;
      }
    }

    .nav-section {
      width: 173px;

      @include respond($break130) {
        width: 13vw;
      }

      @include respond($break100) {
        width: 100%;
      }

      &:last-child {
        margin-right: $containerPadding25;

        @include respond($break110) {
          margin-left: 2.2vw;
        }
      }

      + .nav-section {
        margin-left: $containerPadding25;

        @include respond($break110) {
          margin-left: 2.2vw;
        }

        @include respond($break100) {
          margin-left: 0;
        }
      }
    }

    .heading-quinary {
      padding-bottom: 2px;
      font-size: inherit;
      line-height: 2;

      @include respond($break100) {
        padding-bottom: 10px;
      }

      a {
        @include thinGlyphs;
        font-weight: $black;
        text-transform: uppercase;

        @include respond($break100) {
          text-decoration: none;
        }
      }
    }

    li {
      padding-bottom: 2px;
      line-height: 1.833;

      li {
        @include respond($break100) {
          display: none;
        }

        a {
          font-weight: $book;
        }
      }
    }
  }

  .social-navigation {
    ul {
      @include listHorizontal;
    }

    @include respond($break130) {
      li {
        display: block;
        padding-bottom: 2px;
      }
    }

    @include respond($break100) {
      display: none;
    }

    .icon {
      width: 43px;
      height: 43px;

      @include respond($break90) {
        width: 4.5vw;
        height: 4.5vw;
      }
    }
  }

  .secondary-nav-container {
    margin-top: -20px;

    @include respond($break130) {
      margin-top: -1.563vw;
    }

    @include respond($break90) {
      margin-top: 0;
    }
  }
}
