.contact-method-cards-grid {
  display: grid;
  grid-template-columns: 1.1fr 1.75fr 1.25fr 1fr;
  grid-auto-flow: row;
  gap: 16px;
  max-width: 100%;

  @include respond($break115, max) {
    grid-template-columns: 1.25fr 1.85fr 1.25fr 1fr;
    gap: 16px;
  }

  @include respond($break110, max) {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  @include respond($break60, max) {
    grid-template-columns: 1fr;
  }

  .contact-method-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.333;
    min-width: 200px;
    flex-grow: 1;

    p {
      width: 100%;
      white-space: pre-line;
      padding-inline: 25px;
    }

    button {
      @include buttonUnstyled;
      @include linkPrimary;
      @include hoverFade;
    }
  }

  .clipped-box {
    @include clippedBox(15px, 10px);
    padding-block-start: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: map-get($accent, 1);
    width: 100%;
    max-height: 61px;
    margin-block-end: 16px;
    padding-inline: 12px;

    h3 {
      @include headingQuaternary;
      line-height: 1;
      color: $neutralWhite;
      font-weight: 900;
      text-transform: uppercase;
      padding-block-start: 15px;
    }

    > #phone-icon,
    #call-back-icon,
    #people-icon,
    #globe-icon {
      flex-shrink: 0;
      width: 36px;
      height: 36px;

      @include respond($break110, max) {
        width: 30px;
        height: 30px;
      }
    }

    > #phone-icon {
      background-image: url("/themes/healthshare/assets/svg/phone.svg");
      background-position: center;
      background-size: cover;
      width: 40px;
      height: 40px;
      margin-block-start: 10px;
      margin-right: -4px;

      @include respond($break110, max) {
        width: 32px;
        height: 32px;
      }
    }

    > #call-back-icon {
      background-image: url("/themes/healthshare/assets/svg/call-back.svg");
      background-position: contain;
      background-size: cover;
      width: 42px;
      height: 42px;
      margin-right: -2px;

      @include respond($break110, max) {
        width: 34px;
        height: 34px;
      }
    }

    > #people-icon {
      background-image: url("/themes/healthshare/assets/svg/people.svg");
      background-position: center;
      background-size: cover;
    }

    > #globe-icon {
      background-image: url("/themes/healthshare/assets/svg/globe.svg");
      background-position: center;
      background-size: cover;
    }
  }
}

.call-back-form-modal {
  @include boxShadow;

  border: none;
  position: fixed;
  top: -10%;

  &::backdrop {
    background-color: $neutralWhite;
    opacity: 0.8;
  }
}

.call-back-form-wrapper {
  padding: 30px;
  width: 600px;
  max-width: 98%;
  min-height: 393px;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    @include headingTertiary;
    color: map-get($accent, 1);
    margin-block-end: 10px;
  }

  p {
    margin-block-end: 25px;
  }

  .success {
    display: none;

    h4 {
      margin-block-end: 25px;
    }

    &.visible {
      display: block;
    }

    .button-group {
      padding-block-start: 25px;
    }
  }

  .call-back-form {
    width: 100%;

    > *:not(h4) + * {
      margin-block-start: 24px;
    }

    &.hidden {
      display: none;
    }

    .error {
      display: none;
      @include headingQuaternary;
      padding-block-start: 10px;

      &.visible {
        display: block;
      }
    }

    .field {
      width: 100%;

      label {
        margin-block-end: 4px;
        text-transform: uppercase;
        @include fluidType(14px, 12px);
        display: block;
        font-weight: $bold;

        .required {
          margin-left: 2px;
          font-weight: $black;
          color: map-get($accent, 1);
        }
      }

      > .select-wrapper {
        position: relative;
        width: 100%;

        > .caret-icon {
          background-image: url("/themes/healthshare/assets/svg/caret-bold.svg");
          background-position: contain;
          background-size: cover;
          position: absolute;
          right: 10px;
          top: 25%;
          inline-size: 1em;
          block-size: 1em;
          pointer-events: none;
        }
      }

      input,
      select {
        width: 100%;
        display: block;
        border: 1px solid;
        padding-block-start: 8px;
        paddin-block-end: 6px;
        padding-inline: 10px;
        background-color: transparent;
        appearance: none;
      }

      > .checkbox {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
          display: block;
          margin-block-start: 2px;
          padding-block-start: 1px;
          text-transform: none;
          font-weight: $book;
          @include fluidType(16px, 14px);
        }

        input[type="checkbox"] {
          display: inline-block;
          width: 20px;
          height: 20px;
          cursor: pointer;
          border-radius: 1px;
          appearance: none;

          &:checked {
            background: map-get($accent, 1);
            background-image: url("/themes/healthshare/assets/svg/check.svg");
            background-position: center;
            background-size: cover;
          }
        }
      }
    }
  }

  .button-group {
    width: max-content;
    margin-left: auto;
    display: flex;
    gap: 6px;
    padding-block-start: 20px;

    button {
      @include hoverFade;
    }

    .cancel::before {
      background-color: $neutral60;
    }
  }
}
