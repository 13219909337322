.post-pagination {
  .icon {
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }

  .page-select {
    padding: 23px 0 21px;
    color: var(--colorPrimary);
    text-align: center;
    border-top: 2px solid $neutral40;
    border-bottom: 2px solid $neutral40;

    a {
      @include linkUnstyled;
      @include hoverFade;

      &:not(:last-child) {
        padding-right: 32px;

        @include respond($break70) {
          padding-right: 27px;
        }
      }
    }
  }

  .page-number {
    display: inline-block;
    font-size: 12px;

    &.active {
      font-weight: $bold;
      text-decoration: underline;
    }
  }

  .caret-back {
    .icon {
      transform: rotate(90deg);
    }
  }
}
