.m-notification {
  padding-block: 15px;
  font-weight: 500;

  &.bg-alert {
    background-color: $baseSecondary;
  }

  &.bg-gray {
    background-color: $neutral20;
  }

  &__inner {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    align-items: center;
    margin-inline: auto;
    @include fluid-scale(gap, 16px, 4px);

    @include respond($break30) {
      display: flex;
    }
  }

  &__text {
    flex: 1 1 auto;
    @include thinGlyphs;
    color: $basePrimaryDarker;
    text-align: center;
    margin: auto;
    max-width: 1000px;
    grid-column-start: 2;

    &--headline {
      text-transform: uppercase;
      font-weight: $black;
    }

    p {
      padding: 5px 0;
    }

    a {
      text-decoration: underline;
      color: $basePrimaryDarker;
      font-weight: $black;
    }
  }

  &__close {
    @include buttonUnstyled;
    @include focusState;
    color: $basePrimaryDarker;
    padding: 10px;
    flex: 0 0 auto;
    cursor: pointer;
    transition: $colorTransition;

    &:hover {
      color: $basePrimaryDark;
    }
  }

  &__close-svg {
    width: 20px;
    height: 20px;
  }
}
