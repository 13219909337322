.immunization-list {

  @include respond($containerBaseWidth) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .inner-container {
    max-width: 1128.92px;
    padding: $containerPadding40;
    background: $neutralWhite;

    @include respond($break80) {
      padding: $containerPadding40 30px;
    }
  }

  .logo {
    width: 114px;
    height: 91.78px;
    margin-bottom: auto;
    margin-left: $containerPadding20;

    @include respond($break110) {
      width: 10.363vw;
    }

    @include respond($break80) {
      width: 60px;
      margin-bottom: 0;
    }
  }

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      color: map-get($accent, 3);

      @media print {
        font-size: 20px;
        color: $neutral90;
      }
    }

    p {
      font-weight: $bold;

      + p {
        margin-top: 14px;
      }
    }
  }

  .table {
    margin-top: $containerPadding40;

    @include respond($break80) {
      display: none;
    }

    @media print {
      display: block;
      margin-top: 0;

      td {
        font-size: 14px;
      }
    }
  }

  td {
    // overwrites table styles that required !important for IE11 compatibiloty
    width: auto !important; //stylelint-disable-line
    padding: $containerPadding20 0;

    &:not(:last-child) {
      padding-right: 50px;
    }
  }

  tr {
    border-bottom: 2px solid $neutral40;
    break-inside: avoid;
  }

  .age {
    width: 20% !important; //stylelint-disable-line
  }

  .vaccines {
    width: 48% !important; //stylelint-disable-line
  }

  .age-cell {
    font-weight: $bold;
  }

  .checkbox {
    width: 22px;
    height: 22px;
    margin: 0 auto;
    border: 2px solid $basePrimary;
    border-radius: 100%;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }

  .list-mobile {
    display: none;

    @include respond($break80) {
      display: block;
    }

    @media print {
      display: none;
    }
  }

  .icon {
    margin-left: auto;
  }

  .accordion-button {
    width: 100%;
  }

  .age-mobile {
    font-weight: $black;
  }

  .accordion {
    border-bottom: 2px solid $neutral40;
  }

  .accordion-inner {
    padding-bottom: $containerPadding15;
  }

  .footnote {
    padding-top: 35px;

    @include respond($break80) {
      padding-top: $containerPadding20;
    }
  }

  .print-button {
    @include hoverFade;

    &::before {
      background: map-get($accent, 1);
    }

    @include respond($break80) {
      display: none;
    }

    @media print {
      display: none;
    }
  }
}
