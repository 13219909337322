// Image
//
// Simple image that scales to the width of its container and is typically nested inside a Two-Column Wrapper CE. The height is constrained on mobile.
//
// Fields:
// * `image`
// * `alt`
//
// Markup: kss-markup/image.html
//
// Weight: 4
//
// Styleguide Content-Elements.Image
.image-element {
  width: 100%;

  @include respond($break70) {
    max-height: 348px;
    object-fit: cover;
  }

  &--width-750 {
    display: block;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;

    @include respond($break70) {
      max-width: none;
    }
  }
}
