// Three Columns
//
// A columnar text element with three RTE columns and an optional heading and subheading
//
// Fields: `background_color`, `heading`, `subheading`, `col1`, `col2`, `col3`
//
// Markup: kss-markup/three-column.html
//
// Weight: 6
//
// Styleguide Content-Elements.ThreeColumns

.three-column {
  // break email addresses so that long ones don't interfere with column layouts or overflow on mobile
  a[href^='mailto:'] {
    word-break: break-all;
  }

  header {
    h2 {
      @include respond($break70) {
        padding-bottom: $containerPadding15;
      }
    }

    p {
      padding-bottom: 32px;

      @include respond($break70) {
        padding-bottom: 18px;
      }
    }
  }

  .three-column-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include respond($break70) {
      flex-direction: column;
    }

    .column {
      flex: 1;
      min-width: 0;

      @include respond($break70) {
        flex: 0 auto;
        width: 100%;
      }

      h4:first-child {
        padding-top: 2px;
      }

      + .column {
        padding-left: $containerPadding40;

        [dir='rtl'] & {
          padding-right: $containerPadding40;
          padding-left: 0;
        }

        @include respond($break130) {
          padding-left: 3.2vw;

          [dir='rtl'] & {
            padding-right: 3.2vw;
            padding-left: 0;
          }
        }

        @include respond($break70) {
          padding-top: 28px;
          padding-left: 0;

          [dir='rtl'] & {
            padding-right: 0;
          }
        }
      }
    }

    h4 {
      + p,
      + ul,
      + ol {
        padding-top: 10px;
      }
    }
  }

  //these styles account for images and long email addresses currently in this element in the staff pages. Client will probably wnat to rethink approach to this content.
  img {
    max-width: 100%;

    @include respond($break70) {
      width: 100% !important; //stylelint-disable-line
    }
  }
}
