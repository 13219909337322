.content-rte, .content-default {
  @include copyPrimary;

  // Headings
  // --------------------------
  h1, h2, h3, h4, h5 {
    a {
      @include linkPrimary;
      @include hoverFade;
      font-weight: inherit;
    }
  }

  h1, h2 {
    padding-bottom: $containerPadding40;

    @include respond($break130) {
      padding-bottom: 3.125vw;
    }

    @include respond($break50) {
      padding-bottom: $containerPadding15;
    }
  }

  h1 {
    @include headingPrimary;
  }

  h2 {
    @include headingSecondary;
  }

  h3, h4 {
    padding-bottom: 6px;

    @include respond($break50) {
      padding-bottom: 4px;
    }

    + ol, + ul {
      padding-top: 13px;
    }

    @include respond($break50) {
      ul {
        padding-top: 0;
      }

      ol {
        padding-top: 6px;
      }
    }
  }

  h3 {
    @include headingTertiary;
  }

  h4 {
    @include headingQuaternary;

    a {
      padding-top: 4px;
    }
  }

  h5 {
    @include headingQuinary;
  }

  // Copy
  // --------------------------
  p, ul, ol, tr {
    a {
      @include linkPrimary;
      @include hoverFade;
      padding-top: 2px;

      &.button-primary {
        display: inline-block;
        padding-top: 15px;
        font-weight: $black;
        color: $neutralWhite;
        text-decoration: none;
      }
    }

    + p,
    + h3,
    + h4,
    + .button-primary,
    + ol {
      margin-top: 32px;

      @include respond($break90) {
        margin-top: 3.36vw;
      }

      @include respond ($break50) {
        margin-top: 22px;
      }
    }
  }

  ol {
    @include orderedListPrimary;
  }

  ul {
    @include listPrimary;
  }

  a[href^='tel:'] {
    white-space: nowrap;
  }

  // for sets of primary links without full paragraph spacing between them. These can be added in the backend richtext field using shift return
  br {
    + a {
      display: inline-block;
      margin-top: 10px;
    }
  }

  td {
    width: 49.3% !important; //stylelint-disable-line
    padding-bottom: 10px;
  }

  // special table heading style, primarily for use in tables within accordion FAQs
  .table-heading {
    @include headingQuaternary;
    padding-top: $containerPadding20;
    padding-bottom: 13px;
    color: var(--colorPrimary);
    border-top: 2px solid var(--colorPrimary);
    border-bottom: 2px solid var(--colorPrimary);

    @include respond($break70) {
      border-top: none;
      border-bottom: none;
    }
  }

  // Buttons
  // --------------------------
  .button-primary {
    margin-right: $containerPadding40;

    @include respond($break50) {
      + .button-primary {
        margin-top: $containerPadding25;
      }
    }
  }

  // Images
  // --------------------------
  p {
    > img {
      max-width: 100%;
    }
  }
}
