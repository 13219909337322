// Component Structure
// -------------------

// Structure-only mixins (and maybe utility classes), with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context can use em units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary
// for scaling

// Layout
// --------------------------------------------------------

// Container
//
// The highest-level container for page content. Sets a max-width and centers its children.
//
// Usage: `.container` or `@include container`
//
// Styleguide STACSS.Structure.Container

@mixin container {
  max-width: $containerMaxWidth;
  margin: 0 auto;
}

.container {
  @include container;
}

// Container Primary
//
// Lives inside `.container`. A base container with equal right and left padding.
//
// Usage: `.container-primary` or `@include containerPrimary`
//
// Styleguide STACSS.Structure.ContainerPrimary

@mixin containerPrimary {
  max-width: $containerBaseWidth + 2 * $containerPadding75;
  padding-right: $containerPadding75;
  padding-left: $containerPadding75;
  margin: 0 auto;

  @include respond($break130) {
    padding-right: 5.86vw;
    padding-left: 5.86vw;
  }

  @include respond($break50) {
    padding-right: $containerPadding20;
    padding-left: $containerPadding20;
  }

  > h4:first-child {
    padding-top: 2px;
  }
}

.container-primary {
  // Lives inside a .container
  @include containerPrimary;
}

// Content Wrapper Primary
//
// Sets a padding of `$containerPadding40` around an element.
//
// Usage: `.content-wrapper-primary` or `@include contentWrapperPrimary`
//
// Styleguide STACSS.Structure.ContentWrapperPrimary

@mixin contentWrapperPrimary {
  padding: $containerPadding40;
}

.content-wrapper-primary {
  @include contentWrapperPrimary;
}

// Content Wrapper Secondary
//
// Sets a padding of `$containerPadding25` around an element.
//
// Usage: `.content-wrapper-secondary` or `@include contentWrapperSecondary`
//
// Styleguide STACSS.Structure.ContentWrapperSecondary

@mixin contentWrapperSecondary {
  padding: $containerPadding25;
}

.content-wrapper-secondary {
  @include contentWrapperSecondary;
}

.l-pad-top-responsive {
  @include fluid-scale(margin-top, 45px, 32px);
}
